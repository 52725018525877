import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';


export const medicalcertificateHistoryList = (state = [], action) => {
  switch (action.type) {
    case Constants.MEDICAL_CERTIFICATE_HISTORIES_GET_SUCCESS:
      const items = action.payload.results.map(item => ({
        id: item.id,
        type: item.type,
        staff: ((staff) => {
          return {
            name: staff.first_name
          }
        })(item.staff),
        symptom: ((closed) => {
          return closed.illness_text
        })(item.closed),
        detail: item.detail,
        file: item.result_file,
        created: item.created
      }));
      return items;

    default:
      return state;
  }
};

export default combineReducers({
  medicalcertificateHistoryList,
});