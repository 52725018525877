import moment from 'moment';
import 'moment/locale/th';
moment.locale('th');

export const getDate = date => {
  return (date ? new Date(date) : new Date()).toISOString().slice(0, 10);
};

export const getDateAppointment = ch => {
  const date = ch.appointment ? ch.appointment.time_slot.date : '';
  const start_time = ch.appointment ? ch.appointment.time_slot.start_time : '';
  return parseAppointmentDateTime(date, start_time);
};

export const sortByDate = (a, b) => {
  const bDate = getDateAppointment(b);
  const aDate = getDateAppointment(a);
  return aDate - bDate;
};

export const deliveryTomorrow = () => {
  let daysToAdd = 0;
  if (new Date().getHours() >= 19) daysToAdd = 1;
  return moment()
    .add(daysToAdd, 'days')
    .set({ hour: 10, minute: 0, second: 0, millisecond: 0 })
    .toDate();
};

export const renderChatTime = date => {
  return moment(date).format('LT');
};

export const renderChatDate = date => {
  return moment(date).format('Do MMM');
};

export const renderRegularDate = date => {
  return moment(date).format('Do MMM YYYY');
};

export const isSameDate = (d1, d2) => {
  const date1 = d1
    .toLocaleString()
    .replace(/ /g, '')
    .split(',')[0];
  const date2 = d2
    .toLocaleString()
    .replace(/ /g, '')
    .split(',')[0];
  return date1.localeCompare(date2) === 0;
};

export const parseAppointmentDateTime = (date, time) => {
  return new Date(`${date}T${time}+07:00`);
};

export const renderAppointmentDateTime = date => {
  return moment(date).format('lll');
};

export const renderRelativeDateTime = date => {
  return moment(date).fromNow();
};

export const dateFromTime = time => {
  return new Date(`${getDate()}T${time}+07:00`);
};

export function formatCalendarDate(date) {
  if (!date) return '';
  let month = '' + (date.getMonth() + 1);
  let day = '' + date.getDate();
  let year = date.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
}

export const isAvailablewithTimeSlot = time => {
  let available = false;
  const fastestTime = new Date(time);
  const now = new Date();
  const difference = fastestTime.getTime() - now.getTime();
  const timeCheck = 60 * 60000;
  if (difference <= timeCheck) available = true;
  return available;
};

export const getAgefromBirthDate = (birthday) => {
  return moment().diff(birthday, 'years')
}

export const renderDateTime = date => {
  return moment(date).format('D MMMM YYYY, H:mm');
}

export const renderDate = date => {
  return moment(date).format('D MMMM YYYY');
}

// export const MONTHS = [
//   'ม.ค.',
//   'ก.พ.',
//   'มี.ค.',
//   'เม.ย.',
//   'พ.ค.',
//   'มิ.ย.',
//   'ก.ค.',
//   'ส.ค.',
//   'ก.ย.',
//   'ต.ค.',
//   'พ.ย.',
//   'ธ.ค.',
// ];

export const MONTHS = {
  'full' : ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
  'initial' : ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'],
}