import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    opacity: 0.3,
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black'
  },
}));

export default () => {
  const classes = useStyles();
  const session = useSelector(state => state.session);

  if (session.isReady) return false;

  return (
    <div className={classes.backdrop}>
      {/* <CircularProgress color="inherit" /> */}
    </div>
  )
};