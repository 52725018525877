import * as Constant from '../../actions/constants'

export default () => next => action => {
  if (window.logEvent && action.type) {
    if (action.type === Constant.LOCATION_CHANGE) {
      window.logEvent('screen_view');
    }
  }
  return next(action);
}
