import axios from 'axios';

import RequestHelper from './requestHelper';

export const encode = object =>
  new URLSearchParams(
    Object.keys(object).reduce(
      (prev, key) => (!object[key] ? prev : { ...prev, [key]: object[key] }),
      {},
    ),
  ).toString();

export const buildUrlWithParams = (requestURL, params) => {
  const queryParams = Object.entries(params)
    .filter(([key, value]) => value !== null && value !== undefined)
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join('&');

  const url = `${requestURL}?${queryParams}`;
  
  return url;
}


export const post = (url, payload) => RequestHelper.post(url, payload);

// export const logInLine = (access_token, code) => RequestHelper.logInLine(access_token, code);
export const logInToken = token => RequestHelper.logInToken(token);

export const logInLine = (accessToken, otpData) =>
  RequestHelper.logInLine(accessToken, otpData);

export const logInFacebook = (accessToken, otpData) =>
  RequestHelper.logInFacebook(accessToken, otpData);

export const logInTwilio = () => RequestHelper.logInTwilio();

export const logIn = (username, password) =>
  RequestHelper.logIn(username, password);

export const logOut = () => RequestHelper.logOut();

export const updateMe = (first_name, last_name, email) =>
  RequestHelper.updateMe(first_name, last_name, email);

export const become = () => RequestHelper.me();

export const doctorRegistration = formData =>
  RequestHelper.postFormData('auth/line/staff/register/', formData);

export const postRequestOTP = phone =>
  RequestHelper.post('auth/request_otp/', { phone });

export const postVerifyOTP = (pin, phone, otp_token) =>
  RequestHelper.post('auth/verify_otp/', { pin, phone, otp_token });

export const getProvinces = () => RequestHelper.get(`user/provinces/`);

export const postUserProfile = ({ gender, birthday, province }) =>
  RequestHelper.post(`user/user-profile/`, { gender, birthday, province });

export const getQuestionnaireById = id =>
  RequestHelper.get(`questionnaires/${id}/`);

export const getQuestionnaires = (categoryId, page) =>
  RequestHelper.get(`questionnaires/?page=${page}&category_id=${categoryId}`);

export const getResult = id =>
  RequestHelper.get(`questionnaires/${id}/result/`);

export const postSubmitQuestionnaire = (id, answers, user_relation, cardId, partnerId, partners) => {
  if (partners) {
    return RequestHelper.post(`corporate/${partnerId}/questionnaire/user-questionnaire/?card=${cardId}`, {
      user_relation,
      answers,
      questionnaire: id,
    });
  } else {
    return RequestHelper.post(`questionnaires/user-questionnaire/`, {
      user_relation,
      answers,
      questionnaire: id,
    });
  }
}

export const patchUserQuestionnaire = (id, data) =>
  RequestHelper.patch(`questionnaires/user-questionnaire/${id}/`, data);
export const getUserQuestionnaireCount = () =>
  RequestHelper.get('questionnaires/user-questionnaires/count/');

export const getBlogs = (queryString = '') =>
  RequestHelper.get(`blogs/?${queryString}`);

export const getBlogById = id => RequestHelper.get(`blogs/${id}`);

export const getBanners = () => RequestHelper.get(`user/banners`);

export const getProducts = (categoryId, search = '') =>
  RequestHelper.get(
    `blogs/products/?category_id=${categoryId}&search=${search}`,
  );
export const getProduct = productId =>
  RequestHelper.get(`blogs/products/${productId}`);

export const getLocations = (categoryId, search = '') =>
  RequestHelper.get(
    `blogs/locations/?category_id=${categoryId}&search=${search}`,
  );
export const updateCountLocation = locationId =>
  RequestHelper.post(`user/count-location/${locationId}/`);
export const updateAcceptHolistic = () =>
  RequestHelper.post(`user/accept-holistic/`);
export const getHolisticScore = () =>
  RequestHelper.get(`user/holistic-summary/`);

// external api
export const getTodaySummaryStatistic = () =>
  axios.get('https://covid19.th-stat.com/api/open/today');

export const getTodayProvinceStatistic = () =>
  axios.get('https://covid19.th-stat.com/api/open/cases/sum');

export const getTimelineStatistic = () =>
  axios.get('https://covid19.th-stat.com/api/open/timeline');

export const getLineToken = payload =>
  axios.post('https://api.line.me/oauth2/v2.1/token', payload, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

export const getUserRelations = () => RequestHelper.get('user/user-relations/');
export const addUserRelation = data =>
  RequestHelper.post('user/user-relations/', data);
export const removeUserRelation = relationId =>
  RequestHelper.delete(`user/user-relations/${relationId}/`);

export const postAppointment = (data, isMember, cardId, partnerId, partners) => {
  if (partners) {
    return RequestHelper.post(`corporate/${partnerId}/appointment/?card=${cardId}`, data);
  } else if (isMember) {
    return RequestHelper.post(`membership/appointments/?card=${cardId}`, data);
  } else {
    return RequestHelper.post('appointments/', data);
  }
  // } else {
  //   return RequestHelper.post((isMember ? 'membership/' : '') + 'appointments/', data);
  // }
}

export const postPackageOrder = data =>
  RequestHelper.post('appointments/package-orders/', data);

export const getCategories = (hospital_id, isMember, cardId, partnerId, partners=false) => {
  if (partners) {
    return RequestHelper.get(`corporate/${partnerId}/categories/?${encode({
      card: cardId,
      is_not_empty: true,
      hospital_id,
    })}`
  );
  } else {
    return RequestHelper.get(
      (isMember ? 'membership/' : '') +
        `appointments/categories/?${encode({
          is_not_empty: true,
          hospital_id,
        })}`,
    );
  }
}

export const getCategoryById = (categoryId, isMember) =>
  RequestHelper.get(
    (isMember ? 'membership/' : '') + `appointments/categories/${categoryId}/`,
  );

export const getConsultants = (
  categoryId,
  hospitalId = undefined,
  isMember,
  cardId,
  partnerId,
  partners,
  isOnlineAndConsultNowAvailable = undefined
) => {
  const url = (partners)
    ? `corporate/${partnerId}/consultants/`
    : (isMember)
      ? 'membership/appointments/consultants/'
      : 'appointments/consultants/';
  
  const params = (partners)
    ? {
        category_id: categoryId,
        hospital_id: hospitalId,
        card: cardId,
        is_online_and_consult_now_available_only: isOnlineAndConsultNowAvailable
      }
    : {
        category_id: categoryId,
        hospital_id: hospitalId
      };

  const requestURL = buildUrlWithParams(url, params);
  return RequestHelper.get(requestURL);
}

export const getConsultantsNow = categoryId =>
  RequestHelper.get(
    `appointments/consultants/consultnow/?category_id=${categoryId}&ordering=datetime_slot&limit=6`,
  );

export const getTimeSlots = (consultantId, isMember, cardId, partnerId, partners) => {
  if (partners) {
    return RequestHelper.get(
      `corporate/${partnerId}/consultant-time-slots/?consultant_id=${consultantId}&available=true&card=${cardId}`);
  } else {
    return RequestHelper.get(
      (isMember ? 'membership/' : '') +
        `appointments/consultant-time-slots/?consultant_id=${consultantId}&available=true`,
    );
  }
}

export const postSummaryAppointment = (data, partnerId, cardId, partners) => {
  if (partners) {
    return RequestHelper.post(`corporate/${partnerId}/appointment/summary/?card=${cardId}`, data);
  } else {
    return RequestHelper.post(`appointments/summary/`, data);
  }
}

export const getListPromotionAppointment = () =>
  RequestHelper.get(`promotions/collect/customer/`);

export const postSearchandCollectPromotionAppointment = payload =>
  RequestHelper.post(`promotions/searchandcollect/`, payload);

// wrong method and naming
export const postCountData = (code, user_questionnaire_id = '') =>
  RequestHelper.post(`user/count-data/${code}/`, {
    code,
    user_questionnaire_id,
  });

export const updateCountProduct = productId =>
  RequestHelper.post(`user/count-product/${productId}/`);

export const sendResultMessage = questionnaireId =>
  RequestHelper.post(`questionnaires/${questionnaireId}/send-result-message/`);

export const postCreditCard = token =>
  RequestHelper.post('customer/cards/', { token });

export const getCreditCards = () => RequestHelper.get('customer/cards/');

export const patchCreditCard = (id, payload) =>
  RequestHelper.patch(`customer/cards/${id}/`, payload);

export const getPromptpay = url =>
  RequestHelper.get(`/promptpay/download/?scannable_url=${url}/`);

export const removeCreditCard = id =>
  RequestHelper.delete(`customer/cards/${id}/`);

export const getMenusWithLimit = limit => (
  search,
  categoryId,
  page,
  lat,
  lng,
  filter,
) =>
  RequestHelper.get(
    `product/?${encode({
      limit,
      search,
      offset: page ? (page - 1) * limit : 0,
      categories__id: categoryId ? categoryId : undefined,
      lat,
      lng,
      ...filter,
    })}`,
  );

export const getMenus = getMenusWithLimit(12);

export const getMenu = (ids, tenant) => {
  let res = ids;
  if (Array.isArray(ids)) {
    res = ids.join(',');
  }
  return RequestHelper.get(
    `product/?${encode({
      id: res,
      tenant: tenant,
    })}`,
  );
};

export const getMenusForDoctorWithLimit = limit => (search, chatroom) =>
  RequestHelper.get(
    `chatrooms/staff/${chatroom}/products/?${encode({
      limit,
      search,
    })}`,
  );
// export const getMenu = ids => {
//   let res = ids;
//   if (Array.isArray(ids)) {
//     res = ids.join(',');
//   }
//   return RequestHelper.get(`product/?id=${res}`);
// }

export const getPrescriptionResources = () =>
  RequestHelper.get(`product/prescriptionresource/`);

export const getMenuCategories = () => RequestHelper.get(`product/category/`);

export const postOrder = (payload, membership = false, options = {}) => {
  if (membership) {
    const url = 'membership/pharmacy/order/';
    const requestURL = buildUrlWithParams(url, options);
    return RequestHelper.post(requestURL, payload);
  } else {
    return RequestHelper.post(`order/`, payload);
  }
};

export const postOrderSummary = (payload, membership = false, options = {}) => {
  if (membership) {
    const url = 'membership/pharmacy/order/summary/';
    const requestURL = buildUrlWithParams(url, options);
    return RequestHelper.post(requestURL, payload);
  } else {
    return RequestHelper.post(`order/summary/`, payload);
  }
};

export const getOrder = id => RequestHelper.get(`order/${id}/`);

export const getOrders = () => RequestHelper.get(`order/?limit=20`);

export const getAddresses = () => RequestHelper.get('address/');

export const postAddress = (id, payload) => {
  if (id) {
    return RequestHelper.patch(`address/${id}/`, payload);
  } else {
    return RequestHelper.post('address/', payload);
  }
};

export const getShippingPrice = (latitude, longitude, price) =>
  RequestHelper.post(`order/calculate/deliveryprice/`, {
    latitude,
    longitude,
    price,
  });

export const getPatientRecord = () => RequestHelper.get('patientrecord/me/');

export const getPatientList = () =>
  RequestHelper.get('patientrecord/me/patients/');

export const getPatientRecordAssessment = (id, date) =>
  RequestHelper.get(
    `patientrecord/me/patients/${id}/?assessment_datetime=${date}`,
  );

export const postPatientRecord = payload =>
  RequestHelper.patch('patientrecord/me/', payload);

export const postEvaluation = (cardId, partnerId, partners) => {
  if (partners) {
    return RequestHelper.post(`corporate/${partnerId}/evaluation/?card=${cardId}`);
  } else {
    return RequestHelper.post('evaluations/');
  }
}

export const closeEvaluation = (id, payload) =>
  RequestHelper.post(`evaluations/${id}/close/`, payload);

export const rateEvaluation = (id, payload) =>
  RequestHelper.post(`evaluations/${id}/rating/`, payload);

export const rateQuestionnaire = (id, payload) =>
  RequestHelper.post(
    `questionnaires/user-questionnaire/${id}/rating/`,
    payload,
  );

export const rateChat = (id, payload) =>
  RequestHelper.post(`chatrooms/customer/${id}/rating/`, payload);

export const getChatroom = (isStaff, id) =>
  RequestHelper.get(`chatrooms/${isStaff ? 'staff' : 'customer'}/${id}/`);

export const getChatrooms = (isStaff, payment_status) =>
  RequestHelper.get(
    `chatrooms/${isStaff ? 'staff' : 'customer'}/?${encode({
      payment_status,
    })}`,
  );

export const closeChatroom = (id, payload) =>
  RequestHelper.post(`chatrooms/staff/${id}/close/`, payload);

export const postChatroomEnter = (isStaff, id) =>
  RequestHelper.post(
    `chatrooms/${isStaff ? 'staff' : 'customer'}/${id}/enterroom/`,
  );

export const getChatroomConsent = id =>
  RequestHelper.get(`chatrooms/staff/${id}/consent/request/`);

export const postChatroomConsent = id =>
  RequestHelper.get(`chatrooms/customer/${id}/consent/accept/`);

export const getEvaluationRecords = id =>
  RequestHelper.get(`chatrooms/staff/${id}/history/evaluations/`);

export const getQuestionaireRecords = id =>
  RequestHelper.get(`chatrooms/staff/${id}/history/questionnaires/`);

export const getLabRecords = id =>
  RequestHelper.get(`chatrooms/staff/${id}/history/lab/orders/`);

export const getChatroomRecords = id =>
  RequestHelper.get(`chatrooms/staff/${id}/history/chatrooms/`);

export const checkConsent = name => {
  if (name) {
    return RequestHelper.get_v2(
      `consents/currently/check/?consent_name=${name}`,
    );
  } else {
    return RequestHelper.get(`consents/currently/check/`);
  }
};

export const postAcceptConsent = (payload) => {
  if (payload) {
    return RequestHelper.post_v2(`consents/currently/accept/`, payload);
  } else {
    return RequestHelper.post(`consents/currently/accept/`);
  }
};

export const checkConsentNews = () =>
  RequestHelper.get(`consents/fornews/check/`);

export const postConsentNews = (payload) =>
  RequestHelper.post(`consents/fornews/accept/`, payload);

export const getPatientRecords = id =>
  RequestHelper.get(`patientrecord/patients/${id}/`);

export const getPatientVitalSign = id =>
  RequestHelper.get(`patientrecord/patients/${id}/healthlogs/?limit=1`);

export const getPatientHealth = id =>
  RequestHelper.get(`patientrecord/patients/${id}/vitalsignslogs/?limit=1`);

export const getDisease = id =>
  RequestHelper.get(`patientrecord/disease/${id}/`);

export const getLabList = (type, latitude, longitude) =>
  RequestHelper.get(
    `lab/packages/?${encode({
      limit: 200,
      request_nearest: true,
      package_type: type,
      latitude,
      longitude,
    })}`,
  );

export const getDiseaseList = search =>
  RequestHelper.get(
    `/patientrecord/disease/?limit=10&offset=0&search=${search}`,
  );

export const postLabOrder = payload =>
  RequestHelper.post(`lab/order/`, payload);

export const postLabOrderSummary = payload =>
  RequestHelper.post(`lab/order/summary/`, payload);

export const getLabBranchTimeSlot = (latitude, longitude) =>
  RequestHelper.get(
    `/lab/branchs/nearest/timeslots/?${encode({
      available: true,
      latitude,
      longitude,
    })}`,
  );

export const getLabOrder = id => RequestHelper.get(`lab/order/${id}`);

export const getLabOrders = type =>
  RequestHelper.get(
    `lab/order/?${encode({
      limit: 20,
      package_type: type,
    })}`,
  );

export const postEkyc = payload => RequestHelper.post(`ekyc/`, payload);

export const getCollaboration = (latitude, longitude, chatroom, tenant) =>
  RequestHelper.get(
    `collaboration/nearest/?${encode({
      latitude,
      longitude,
      chatroom,
      tenant,
    })}`,
  );

export const patchUpdateMe = payload =>
  RequestHelper.patch('auth/me/', payload);

export const patchUpdateMeProfileImage = payload =>
  RequestHelper.patch('auth/me/profile-image/', payload);

// redeem

export const postRedeem = payload =>
  RequestHelper.post(`redeem/`, payload);

export const getRedeem = id =>
  RequestHelper.get(`redeem/${id}/`);

//
// Membership
//

export const postMembershipVerify = (payload, partnerId) =>
  RequestHelper.post(`corporate/${partnerId}/verify/`, payload);

export const employeeHealthRegister = (payload, partnerId) =>
  RequestHelper.post(`corporate/${partnerId}/register/`, payload);

export const postMembershipOrder = payload =>
  RequestHelper.post(`membership/order/`, payload);

export const getMembershipOrderDetail = id =>
  RequestHelper.get(`membership/order/${id}`);

export const postMembershipOrderSummary = payload =>
  RequestHelper.post(`membership/order/summary/`, payload);

export const getMembershipCardList = (partners) =>
  RequestHelper.get('membership/me/cards/' + (partners ? `?type__corporate=${process.env.GATSBY_VIRTUALMED_CORPORATE_ID}` : ''))

// export const getMembershipCardList = (corporate) =>
//   RequestHelper.get(`membership/me/cards/?type__corporate=${corporate}`);

export const getCorporateCardList = (partnerId) =>
    RequestHelper.get(`corporate/${partnerId}/me/cards/`);

export const checkCorporateComponentBlock = (partnerId, payload) =>
  RequestHelper.post(`corporate/customer/${partnerId}/componentblock/`, payload);

export const getMembershipCard = id =>
  RequestHelper.get(`membership/me/cards/${id}/`);

export const checkMembershipCardBenefit = (cardId, isAddOnBenefit, benefit) =>
  RequestHelper.get(
    `membership/me/cards/${cardId}/benefits/check/?${encode({
      is_add_on_benefit: isAddOnBenefit ? isAddOnBenefit : false,
      add_on_benefit: benefit
    })}`,
  );

export const getMembershipPlans = () => {
  // return Promise.resolve({
  //   results: [
  //     {
  //       id: 1,
  //       "cardtype": {
  //         "id": "MT2305291685CB8905DC42915",
  //         "code": "MT002001",
  //         "corporate": "CORP2305291685B5748F41140",
  //         "name": "Standard Package",
  //         "live_days": 365,
  //         "front_image": null,
  //         "back_image": null,
  //         "badge": null,
  //         "detail": "",
  //         "price": "1990.00"
  //       },
  //       membership_benefits: [
  //         {
  //           "id": 1,
  //           "detail": "ปรึกษาแพทย์ไม่จำกัดครั้ง"
  //         },
  //         {
  //           "id": 2,
  //           "detail": "ค่ายา มูลค่า 1,200 บาท"
  //         }
  //       ]
  //     },
  //     {
  //       id: 2,
  //       "cardtype": {
  //         "id": "MT2305291685CB8905DC42915",
  //         "code": "MT002001",
  //         "corporate": "CORP2305291685B5748F41140",
  //         "name": "Gold Package",
  //         "live_days": 365,
  //         "front_image": null,
  //         "back_image": null,
  //         "badge": null,
  //         "detail": "",
  //         "price": "5990.00"
  //       },
  //       membership_benefits: [
  //         {
  //           "id": 1,
  //           "detail": "ปรึกษาแพทย์ไม่จำกัดครั้ง"
  //         },
  //         {
  //           "id": 2,
  //           "detail": "วิตามิน อาหารเสริม มูลค่า 1,500 บาท"
  //         },
  //         {
  //           "id": 3,
  //           "detail": "โปรแกรมตรวจสุขภาพ มูลค่า 2,000 บาท"
  //         },
  //         {
  //           "id": 4,
  //           "detail": "ปรึกษาผู้เชี่ยวชาญด้าน wellness 6 ครั้ง"
  //         }
  //       ]
  //     },
  //     {
  //       id: 3,
  //       "cardtype": {
  //         "id": "MT2305291685CB8905DC42915",
  //         "code": "MT002001",
  //         "corporate": "CORP2305291685B5748F41140",
  //         "name": "Platinum Package",
  //         "live_days": 365,
  //         "front_image": null,
  //         "back_image": null,
  //         "badge": null,
  //         "detail": "",
  //         "price": "9990.00"
  //       },
  //       membership_benefits: [
  //         {
  //           "id": 1,
  //           "detail": "ปรึกษาแพทย์ไม่จำกัดครั้ง"
  //         },
  //         {
  //           "id": 2,
  //           "detail": "วิตามิน อาหารเสริม มูลค่า 2,000 บาท"
  //         },
  //         {
  //           "id": 3,
  //           "detail": "โปรแกรมตรวจสุขภาพ มูลค่า 5,000 บาท"
  //         },
  //         {
  //           "id": 4,
  //           "detail": "ปรึกษาผู้เชี่ยวชาญด้าน wellness 12 ครั้ง"
  //         }
  //       ]
  //     }
  //   ]
  // });
  return RequestHelper.get(`membership/plan/`);
};
