
export default () => next => action => {
  if (window) {
    if (action.type && action.type.includes('failed') && action.message && action.alert) {
      if (typeof action.message === 'object') {
        // window.alert(JSON.stringify(action.message))
      } else {
        window.alert(action.message);
      }
    }
  }
  return next(action);
}
