import { createStore, applyMiddleware, compose } from 'redux';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import thunk from 'redux-thunk';

import logEventMiddleware from './middlewares/logEvent';
import errorMiddleware from './middlewares/error';

import reducers from '../reducers/index';

export default (initialState = {}) => {
  const middleware = [logEventMiddleware, errorMiddleware, thunk];

  let devToolsExtension = f => f;
  let sentryReduxEnhancer = f => f;

  if (process.env.GATSBY_API_URL.includes('staging-service.moronline.me')) {
    middleware.push(require('redux-logger').default);

    if (typeof window !== 'undefined' && window.devToolsExtension) {
      devToolsExtension = window.devToolsExtension();
    }
  }
  if (
    process.env.NODE_ENV === 'production' &&
    !!process.env.GATSBY_SENTRY &&
    typeof window !== 'undefined'
  ) {
    Sentry.init({
      dsn: process.env.GATSBY_SENTRY,
      normalizeDepth: 10, // Or however deep you want your state context to be.
      integrations: [new BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.1,
    });
    sentryReduxEnhancer = Sentry.createReduxEnhancer({
      // Optionally pass options listed below
    });
  }

  const enhancer = compose(
    applyMiddleware(...middleware),
    sentryReduxEnhancer,
    devToolsExtension,
  );

  const store = createStore(reducers, initialState, enhancer);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextReducer = require('../reducers').default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
};
