import { combineReducers } from 'redux';
import session from './session';
import api from './api';
import questionnaire from './questionnaire';
import assessmentInfo from './assessmentInfo';
import chatrooms from './chatrooms';
import appointment from './appointment';
import creditCards from './creditCards';
import orderInfo from './orderInfo';
import menus from './menus';
import lab from './lab';
import order from './order';
import promotion from './promotion';
import memberships from './memberships';
import partners from './partners';
import medicalcertificate from './medicalcertificate';
import partnerComponentBlock from './partnerComponentBlock';
import emori from './emori';

export default combineReducers({
  session,
  api,
  questionnaire,
  assessmentInfo,
  chatrooms,
  appointment,
  creditCards,
  orderInfo,
  menus,
  lab,
  order,
  promotion,
  memberships,
  partners,
  medicalcertificate,
  partnerComponentBlock,
  emori,
});
