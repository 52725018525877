import * as Constants from '../actions/constants';

const MAX_SELECTED = 3;

const initialState = {
  assessmentId: null,
  patientRecord: {
    first_name: null,
    last_name: null,
    gender: null,
    birthday: null,
    weight: null,
    height: null,
    blood_group: null,
    blood_group_rh: null,
    medical_condition: null,
    drug_allergy: null,
    allergy: null,
    btemp: null,
    SBP: null,
    DBP: null,
    pulse: null,
    oxygen: null,
    resp_rate: null,
    bloodsugar: null,
  },
  symptoms: {},
  skipAi: false,
  dashboard: null,
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.ASSESSMENT_PATIENT_LATEST_GET_SUCCESS: {
      return {
        ...state,
        dashboard: action.payload,
      };
    }
    case Constants.ASSESSMENT_PATIENT_POST_SUCCESS:
    case Constants.ASSESSMENT_PATIENT_GET_SUCCESS: {
      return {
        ...state,
        patientRecord: action.payload,
      };
    }
    case Constants.SET_ASSESSMENT_SKIP_AI:
      return {
        ...state,
        skipAi: action.payload,
      };
    case Constants.SET_ASSESSMENT_PERSONAL_INFO:
    case Constants.SET_ASSESSMENT_VITAL_SIGN:
      return {
        ...state,
        patientRecord: {
          ...state.patientRecord,
          ...action.payload,
        },
      };
    case Constants.SET_ASSESSMENT_SYMPTOMS:
      const symptoms = {
        ...state.symptoms,
        ...action.payload,
      };
      const selected = Object.values(symptoms).filter(val => val > 0);
      if (selected.length > MAX_SELECTED) {
        return state;
      }
      return {
        ...state,
        symptoms: symptoms,
      };
    case Constants.ASSESSMENT_EVALUATION_POST_SUCCESS:
      return {
        ...state,
        assessmentId: action.payload.id,
      };
    case Constants.APPOINTMENT_POST_SUCCESS:
      return initialState;
    default:
      return state;
  }
}

export const createSelectedSymptom = state => {
  return Object.entries(state.assessmentInfo.symptoms)
    .filter(([key, value]) => value === 1)
    .map(([key]) => key);
};
