import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const byId = (state = {}, action) => {
  switch (action.type) {
    case Constants.ORDERS_GET_SUCCESS: {
      return action.payload.results.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        };
      }, state);
    }
    case Constants.ORDER_GET_SUCCESS: {
      const order = action.payload;

      order.receipt = ((receipt) => {
        if (order.payment_status !== 'paid' || !receipt) return null;

        return {
          id: receipt.id,
          receiptId: receipt.receipt_id,
          fileUrl: receipt.file
        }
      })(order.receipt);

      return {
        ...state,
        [action.payload.id]: order,
      };
    }
    default:
      return state;
  }
};

// const currentSelected = (state = [], action) => {
//   switch(action.type) {
//     case Constants.LAB_ITEM_ADD: {
//       if (state.includes(action.payload.id)) { // Can only add once per item
//         return state;
//       }
//       return [...state, action.payload.id];
//     }
//     case Constants.LAB_ITEM_SET_QUANTITY: {
//       const newState = state.filter(id => action.payload.id !== id);
//       return [...newState, ...Array(action.payload.quantity).fill(action.payload.id)];
//     }
//     case Constants.LAB_ITEM_REMOVE: {
//       const index = state.indexOf(action.payload.id);
//       if (index > -1) {
//         const s = state.slice(index);
//         s.shift();
//         return state.slice(0, index).concat(s);
//       }
//       return state;
//     }
//     default: return state;
//   }
// }

export default combineReducers({
  byId,
  // currentSelected,
});

export const createOrdersSelector = state =>
  Object.keys(state.order.byId).map(id => state.order.byId[id]);
// export const createSelectedItemsSelector = state => {
//   const counts = state.lab.currentSelected.reduce((prev, key) => {
//     return {
//       ...prev,
//       [key]: {
//         ...state.lab.byId[key],
//         count: prev[key] ? prev[key].count + 1 : 1,
//       }
//     };
//   }, {});
//   return {
//     items: Object.keys(counts).map(key => {
//       return {
//         product: counts[key],
//         item_price: counts[key].price,
//         quantity: counts[key].count,
//       }
//     })
//   };
// };
