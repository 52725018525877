import { initializeApp } from 'firebase/app';
import * as Analytics from 'firebase/analytics';

if (
  Analytics.isSupported() &&
  typeof window !== 'undefined' &&
  !window.AnalyticsWebInterface &&
  !(
    window.webkit &&
    window.webkit.messageHandlers &&
    window.webkit.messageHandlers.firebase
  )
) {
  const firebaseConfig = {
    apiKey: process.env.GATSBY_FIREBASE_APIKEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTHDOMAIN,
    projectId: process.env.GATSBY_FIREBASE_PROJECTID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.GATSBY_FIREBASE_APPID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENTID,
  };

  const app = initializeApp(firebaseConfig); // export

  const analytics = Analytics.getAnalytics(app);

  const logEvent = (name, params) => {
    if (!name) {
      return;
    }
    if (!params) params = {};
    params.origin = window.location.origin;
    params.pathname = window.location.pathname;
    Analytics.logEvent(analytics, name, params);
  };

  const setUserId = id => {
    if (!id) {
      return;
    }
    Analytics.setUserId(analytics, id);
  };

  window.logEvent = logEvent;
  window.setUserId = setUserId;
}
