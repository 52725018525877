import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';


export const componentBlock = (state = null, action) => {
  switch (action.type) {
    case Constants.PARTNERS_COMPONENT_BLOCK_GET_SUCCESS:
      return action.payload.component_path;
    
    case Constants.PARTNERS_COMPONENT_BLOCK_GET_NOTFOUND:
      return null;

    default:
      return state;
  }
};

export default combineReducers({
  componentBlock,
});