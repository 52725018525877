export const LOCATION_CHANGE = 'location/change';

export const BECOME_LINE = 'become/line';
export const BECOME_FACEBOOK = 'become/facebook';
export const BECOME_USER = 'become/user';
export const BECOME_TWILIO = 'become/twilio';
export const BECOME_SOURCE = 'become/source';
export const NO_AUTHENTICATION = 'become/anonymous';

export const CHANGED_PROFILE = 'change/profile';

export const UPDATE_ME_REQUEST = 'update/me/request';
export const UPDATE_ME_SUCCESS = 'update/me/success';
export const UPDATE_ME_FAILED = 'update/me/failed';

export const OTP_VERIFY_POST_REQUEST = 'otp/verify/post/request';
export const OTP_VERIFY_POST_SUCCESS = 'otp/verify/post/success';
export const OTP_VERIFY_POST_FAILED = 'otp/verify/post/failed';

export const OTP_POST_REQUEST = 'otp/post/request';
export const OTP_POST_SUCCESS = 'otp/post/success';
export const OTP_POST_FAILED = 'otp/post/failed';

export const LOGIN_REQUEST = 'login/get/request';
export const LOGIN_SUCCESS = 'login/get/success';
export const LOGIN_FAILED = 'login/get/failed';

export const LOGIN_LINE_REQUEST = 'login/line/get/request';
export const LOGIN_LINE_SUCCESS = 'login/line/get/success';
export const LOGIN_LINE_FAILED = 'login/line/get/failed';

export const LOGOUT_REQUEST = 'logout/get/request';
export const LOGOUT_SUCCESS = 'logout/get/success';
export const LOGOUT_FAILED = 'logout/get/failed';

export const SET_QUESTIONNAIRE_ID = 'set/questionaireId';
export const SET_USER_QUESTIONNAIRE_ID = 'set/user/questionaireId';

export const GET_CATEGORIES_REQUEST = 'categories/get/request';
export const GET_CATEGORIES_SUCCESS = 'categories/get/success';
export const GET_CATEGORIES_FAILED = 'categories/get/failed';

export const SET_ASSESSMENT_PERSONAL_INFO = 'assessment/set/personalInfo';
export const SET_ASSESSMENT_VITAL_SIGN = 'assessment/set/vitalSign';
export const SET_ASSESSMENT_SYMPTOMS = 'assessment/set/symptoms';
export const SET_ASSESSMENT_SKIP_AI = 'assessment/set/skipAi';

export const ASSESSMENT_EVALUATION_POST_REQUEST =
  'assessment/evaluation/post/request';
export const ASSESSMENT_EVALUATION_POST_SUCCESS =
  'assessment/evaluation/post/success';
export const ASSESSMENT_EVALUATION_POST_FAILED =
  'assessment/evaluation/post/failed';

export const ASSESSMENT_PATIENT_POST_REQUEST =
  'assessment/patient/post/request';
export const ASSESSMENT_PATIENT_POST_SUCCESS =
  'assessment/patient/post/success';
export const ASSESSMENT_PATIENT_POST_FAILED = 'assessment/patient/post/failed';

export const ASSESSMENT_PATIENT_GET_REQUEST = 'assessment/patient/get/request';
export const ASSESSMENT_PATIENT_GET_SUCCESS = 'assessment/patient/get/success';
export const ASSESSMENT_PATIENT_GET_FAILED = 'assessment/patient/get/failed';

export const ASSESSMENT_PATIENT_LATEST_GET_REQUEST =
  'assessment/patient/latest/get/request';
export const ASSESSMENT_PATIENT_LATEST_GET_SUCCESS =
  'assessment/patient/latest/get/success';
export const ASSESSMENT_PATIENT_LATEST_GET_FAILED =
  'assessment/patient/latest/get/failed';

export const APPOINTMENT_CONSULTANTS_GET_REQUEST =
  'appointment/consultants/get/request';
export const APPOINTMENT_CONSULTANTS_GET_SUCCESS =
  'appointment/consultants/get/success';
export const APPOINTMENT_CONSULTANTS_GET_FAILED =
  'appointment/consultants/get/failed';

export const APPOINTMENT_CONSULTANTS_NOW_GET_REQUEST =
  'appointment/consultants/now/get/request';
export const APPOINTMENT_CONSULTANTS_NOW_GET_SUCCESS =
  'appointment/consultants/now/get/success';
export const APPOINTMENT_CONSULTANTS_NOW_GET_FAILED =
  'appointment/consultants/now/get/failed';

export const APPOINTMENT_CATEGORIES_GET_REQUEST =
  'appointment/categories/get/request';
export const APPOINTMENT_CATEGORIES_GET_SUCCESS =
  'appointment/categories/get/success';
export const APPOINTMENT_CATEGORIES_GET_FAILED =
  'appointment/categories/get/failed';

export const APPOINTMENT_POST_REQUEST = 'appointment/post/request';
export const APPOINTMENT_POST_SUCCESS = 'appointment/post/success';
export const APPOINTMENT_POST_FAILED = 'appointment/post/failed';

export const APPOINTMENT_SUMMARY_POST_REQUEST =
  'appointment/summary/post/request';
export const APPOINTMENT_SUMMARY_POST_SUCCESS =
  'appointment/summary/post/success';
export const APPOINTMENT_SUMMARY_POST_FAILED =
  'appointment/summary/post/failed';

export const SET_PROMOTION = 'set/promotion';
export const REMOVE_PROMOTION = 'remove/promotion';
export const PROMOTION_GET_REQUEST = 'promotion/get/request';
export const PROMOTION_GET_SUCCESS = 'promotion/get/success';
export const PROMOTION_GET_FAILED = 'promotion/get/failed';

export const CREDIT_CARD_POST_REQUEST = 'credit/post/request';
export const CREDIT_CARD_POST_SUCCESS = 'credit/post/success';
export const CREDIT_CARD_POST_FAILED = 'credit/post/failed';

export const CREDIT_CARDS_GET_REQUEST = 'credits/get/request';
export const CREDIT_CARDS_GET_SUCCESS = 'credits/get/success';
export const CREDIT_CARDS_GET_FAILED = 'credits/get/failed';

export const CREDIT_CARDS_REMOVE_REQUEST = 'credits/remove/request';
export const CREDIT_CARDS_REMOVE_SUCCESS = 'credits/remove/success';
export const CREDIT_CARDS_REMOVE_FAILED = 'credits/remove/failed';

export const CREDIT_CARD_PATCH_REQUEST = 'credit/patch/request';
export const CREDIT_CARD_PATCH_SUCCESS = 'credit/patch/success';
export const CREDIT_CARD_PATCH_FAILED = 'credit/patch/failed';

export const MESSAGES_GET_REQUEST = 'chatroom/messages/get/request';
export const MESSAGES_GET_SUCCESS = 'chatroom/messages/get/success';
export const MESSAGES_GET_FAILED = 'chatroom/messages/get/failed';

export const MESSAGE_POST_REQUEST = 'chatroom/messages/post/request';
export const MESSAGE_POST_SUCCESS = 'chatroom/messages/post/success';
export const MESSAGE_POST_FAILED = 'chatroom/messages/post/failed';

export const USERS_DESCRIPTOR_GET_REQUEST =
  'chatroom/users/descriptor/get/request';
export const USERS_DESCRIPTOR_GET_SUCCESS =
  'chatroom/users/descriptor/get/success';
export const USERS_DESCRIPTOR_GET_FAILED =
  'chatroom/users/descriptor/get/failed';

export const CHATROOM_GET_REQUEST = 'chatroom/get/request';
export const CHATROOM_GET_SUCCESS = 'chatroom/get/success';
export const CHATROOM_GET_FAILED = 'chatroom/get/failed';

export const CHATROOMS_GET_REQUEST = 'chatrooms/get/request';
export const CHATROOMS_GET_SUCCESS = 'chatrooms/get/success';
export const CHATROOMS_GET_FAILED = 'chatrooms/get/failed';

export const CHATROOM_ENTER_REQUEST = 'chatroom/enter/request';
export const CHATROOM_ENTER_SUCCESS = 'chatroom/enter/success';
export const CHATROOM_ENTER_FAILED = 'chatroom/enter/failed';

export const CHATROOM_CLOSE_REQUEST = 'chatroom/close/request';
export const CHATROOM_CLOSE_SUCCESS = 'chatroom/close/success';
export const CHATROOM_CLOSE_FAILED = 'chatroom/close/failed';

export const CHATROOM_PATIENT_RECORDS_GET_REQUEST =
  'chatroom/patientrecords/get/request';
export const CHATROOM_PATIENT_RECORDS_GET_SUCCESS =
  'chatroom/patientrecords/get/success';
export const CHATROOM_PATIENT_RECORDS_GET_FAILED =
  'chatroom/patientrecords/get/failed';

export const CHATROOM_LAB_RECORDS_GET_REQUEST =
  'chatroom/labrecords/get/request';
export const CHATROOM_LAB_RECORDS_GET_SUCCESS =
  'chatroom/labrecords/get/success';
export const CHATROOM_LAB_RECORDS_GET_FAILED = 'chatroom/labrecords/get/failed';

export const CHATROOM_EVALUATION_RECORDS_GET_REQUEST =
  'chatroom/evaluationrecords/get/request';
export const CHATROOM_EVALUATION_RECORDS_GET_SUCCESS =
  'chatroom/evaluationrecords/get/success';
export const CHATROOM_EVALUATION_RECORDS_GET_FAILED =
  'chatroom/evaluationrecords/get/failed';

export const CHATROOM_QUESTIONAIRE_RECORDS_GET_REQUEST =
  'chatroom/questionarerecords/get/request';
export const CHATROOM_QUESTIONAIRE_RECORDS_GET_SUCCESS =
  'chatroom/questionarerecords/get/success';
export const CHATROOM_QUESTIONAIRE_RECORDS_GET_FAILED =
  'chatroom/questionarerecords/get/failed';

export const CHATROOM_APPOINTMENT_RECORDS_GET_REQUEST =
  'chatroom/appointmentrecords/get/request';
export const CHATROOM_APPOINTMENT_RECORDS_GET_SUCCESS =
  'chatroom/appointmentrecords/get/success';
export const CHATROOM_APPOINTMENT_RECORDS_GET_FAILED =
  'chatroom/appointmentrecords/get/failed';

export const CHATROOM_CONSENT_GET_REQUEST = 'chatroom/consent/get/request';
export const CHATROOM_CONSENT_GET_SUCCESS = 'chatroom/consent/get/success';
export const CHATROOM_CONSENT_GET_FAILED = 'chatroom/consent/get/failed';

export const CHATROOM_CONSENT_POST_REQUEST = 'chatroom/consent/post/request';
export const CHATROOM_CONSENT_POST_SUCCESS = 'chatroom/consent/post/success';
export const CHATROOM_CONSENT_POST_FAILED = 'chatroom/consent/post/failed';

export const CHATROOM_RATE_POST_REQUEST = 'chatroom/rate/post/request';
export const CHATROOM_RATE_POST_SUCCESS = 'chatroom/rate/post/success';
export const CHATROOM_RATE_POST_FAILED = 'chatroom/rate/post/failed';

export const LAB_ORDER_GET_REQUEST = 'lab/order/get/request';
export const LAB_ORDER_GET_SUCCESS = 'lab/order/get/success';
export const LAB_ORDER_GET_FAILED = 'lab/order/get/failed';

export const LAB_ORDERS_GET_REQUEST = 'lab/orders/get/request';
export const LAB_ORDERS_GET_SUCCESS = 'lab/orders/get/success';
export const LAB_ORDERS_GET_FAILED = 'lab/orders/get/failed';

export const LAB_LIST_GET_REQUEST = 'lab/list/get/request';
export const LAB_LIST_GET_SUCCESS = 'lab/list/get/success';
export const LAB_LIST_GET_FAILED = 'lab/list/get/failed';

export const LAB_ORDER_POST_REQUEST = 'lab/order/post/request';
export const LAB_ORDER_POST_SUCCESS = 'lab/order/post/success';
export const LAB_ORDER_POST_FAILED = 'lab/order/post/failed';

export const LAB_ORDER_SUMMARY_POST_REQUEST = 'lab/order/summary/post/request';
export const LAB_ORDER_SUMMARY_POST_SUCCESS = 'lab/order/summary/post/success';
export const LAB_ORDER_SUMMARY_POST_FAILED = 'lab/order/summary/post/failed';

export const LAB_ITEM_CLEAR = 'lab/clear';
export const LAB_ITEM_ADD = 'lab/add';
export const LAB_ITEM_ID_ADD = 'lab/id/add';
export const LAB_ITEM_ID_REMOVE = 'lab/id/remove';
export const LAB_ITEM_ID_SET_QUANTITY = 'lab/id/set/quantity';

export const MENU_GET_REQUEST = 'menu/get/request';
export const MENU_GET_SUCCESS = 'menu/get/success';
export const MENU_GET_FAILED = 'menu/get/failed';

export const MENUS_GET_REQUEST = 'menus/get/request';
export const MENUS_GET_SUCCESS = 'menus/get/success';
export const MENUS_GET_FAILED = 'menus/get/failed';

export const MENUS_SEARCH_REQUEST = 'menus/search/request';
export const MENUS_SEARCH_SUCCESS = 'menus/search/success';
export const MENUS_SEARCH_FAILED = 'menus/search/failed';

export const MENUS_BY_CATEGORY_REQUEST = 'menus/category/request';
export const MENUS_BY_CATEGORY_SUCCESS = 'menus/category/success';
export const MENUS_BY_CATEGORY_FAILED = 'menus/category/failed';

export const MENUS_CATEGORIES_GET_REQUEST = 'menu/categories/get/request';
export const MENUS_CATEGORIES_GET_SUCCESS = 'menu/categories/get/success';
export const MENUS_CATEGORIES_GET_FAILED = 'menu/categories/get/failed';

export const SET_PROMPTPAY = 'set/promptpay';
export const SET_PAYMENT_TYPE = 'set/payment/type';
export const SET_CASH = 'set/cash';
export const SET_ORDER_INFO = 'set/order/info';
export const SET_SHIPPING_PRICE = 'set/shipping/price';
export const SET_SHIPPING_PRICE_LIST = 'set/shipping/pricelist';
export const SET_SHIPPING_FIXED_PRICE_LIST = 'set/shipping/fixedpricelist';

export const MENU_CLEAR = 'menu/clear';
export const MENU_ADD = 'menu/add';
export const MENU_REMOVE = 'menu/remove';
export const MENU_SET_QUANTITY = 'menu/set/quantity';

export const ORDER_SUMMARY_POST_REQUEST = 'order/summary/post/request';
export const ORDER_SUMMARY_POST_SUCCESS = 'order/summary/post/success';
export const ORDER_SUMMARY_POST_FAILED = 'order/summary/post/failed';

export const ORDER_POST_REQUEST = 'order/post/request';
export const ORDER_POST_SUCCESS = 'order/post/success';
export const ORDER_POST_FAILED = 'order/post/failed';

export const ORDER_GET_REQUEST = 'order/get/request';
export const ORDER_GET_SUCCESS = 'order/get/success';
export const ORDER_GET_FAILED = 'order/get/failed';

export const ORDERS_GET_REQUEST = 'orders/get/request';
export const ORDERS_GET_SUCCESS = 'orders/get/success';
export const ORDERS_GET_FAILED = 'orders/get/failed';

export const ADDRESS_POST_REQUEST = 'address/post/request';
export const ADDRESS_POST_SUCCESS = 'address/post/success';
export const ADDRESS_POST_FAILED = 'address/post/failed';

export const ADDRESSES_GET_REQUEST = 'addresses/get/request';
export const ADDRESSES_GET_SUCCESS = 'addresses/get/success';
export const ADDRESSES_GET_FAILED = 'addresses/get/failed';

export const COLLABORATION_GET_REQUEST = 'collaboration/get/request';
export const COLLABORATION_GET_SUCCESS = 'collaboration/get/success';
export const COLLABORATION_GET_FAILED = 'collaboration/get/failed';

export const MEMBERSHIP_PLAN_GET_REQUEST = 'membership/plan/get/request';
export const MEMBERSHIP_PLAN_GET_SUCCESS = 'membership/plan/get/success';
export const MEMBERSHIP_PLAN_GET_FAILED = 'membership/plan/get/failed';

export const MEMBERSHIP_ORDER_POST_REQUEST = 'membership/order/post/request';
export const MEMBERSHIP_ORDER_POST_SUCCESS = 'membership/order/post/success';
export const MEMBERSHIP_ORDER_POST_FAILED = 'membership/order/post/failed';

export const MEMBERSHIP_ORDER_SUMMARY_POST_REQUEST =
  'membership/order/summary/post/request';
export const MEMBERSHIP_ORDER_SUMMARY_POST_SUCCESS =
  'membership/order/summary/post/success';
export const MEMBERSHIP_ORDER_SUMMARY_POST_FAILED =
  'membership/order/summary/post/failed';

export const MEMBERSHIP_CARDS_GET_REQUEST = 'membership/me/cards/get/request';
export const MEMBERSHIP_CARDS_GET_SUCCESS = 'membership/me/cards/get/success';
export const MEMBERSHIP_CARDS_GET_FAILED = 'membership/me/cards/get/failed';

export const SET_FORCE = 'set/force/membership';
export const SET_CURRENT_MEMBERSHIP_CARD = 'set/current/membership/card';

export const PARTNER_VERIFY_POST_REQUEST = 'partner/verify/post/request';
export const PARTNER_VERIFY_POST_SUCCESS = 'partner/verify/post/success';
export const PARTNER_VERIFY_POST_FAILED = 'partner/verify/post/failed';

export const EMPLOYEEHEALTH_REGISTER_REQUEST = 'employeehealth/register/request';
export const EMPLOYEEHEALTH_REGISTER_SUCCESS = 'employeehealth/register/success';
export const EMPLOYEEHEALTH_REGISTER_FAILED = 'employeehealth/register/failed';

export const PARTNERS_CARDS_GET_REQUEST = 'partner/me/cards/get/request';
export const PARTNERS_CARDS_GET_SUCCESS = 'partner/me/cards/get/success';
export const PARTNERS_CARDS_GET_FAILED = 'partner/me/cards/get/failed';

export const PARTNERS_COMPONENT_BLOCK_GET_REQUEST = 'partner/me/component/block/get/request';
export const PARTNERS_COMPONENT_BLOCK_GET_SUCCESS = 'partner/me/component/block/get/success';
export const PARTNERS_COMPONENT_BLOCK_GET_FAILED = 'partner/me/component/block/get/failed';
export const PARTNERS_COMPONENT_BLOCK_GET_NOTFOUND = 'partner/me/component/block/get/notfound';

export const PARTNER_APPOINTMENT_SUMMARY_POST_REQUEST =
  'partner/appointment/summary/post/request';
export const PARTNER_APPOINTMENT_SUMMARY_POST_SUCCESS =
  'partner/appointment/summary/post/success';
export const PARTNER_APPOINTMENT_SUMMARY_POST_FAILED =
  'partner/appointment/summary/post/failed';

export const MEDICAL_CERTIFICATE_HISTORIES_GET_REQUEST =
  'medicalcertificate/histories/get/request';
export const MEDICAL_CERTIFICATE_HISTORIES_GET_SUCCESS =
  'medicalcertificate/histories/get/success';
export const MEDICAL_CERTIFICATE_HISTORIES_GET_FAILED = 'medicalcertificate/histories/get/failed';

export const SET_MONTH = 'set/month/emori';
export const SET_DATE = 'set/date/emori';
export const SET_RELATION_CORPORATE = 'set/corporate/emori';
export const SET_RELATION_ROOM = 'set/room/emori';
export const SET_RELATION_SEARCH = 'set/search/emori';
export const EMORI_GET_REQUEST = 'emori/get/request';
export const EMORI_GET_SUCCESS = 'emori/get/success';
export const EMORI_GET_FAILED = 'emori/get/failed';
export const SET_CORPORATE_ID = 'set/corporate/id';
export const SET_ADD_ON_BENEFIT = 'set/addon/benefit';
export const SET_CONSULTANT_LIST_AVAILABLE_CONDITION = 'set/consultants/available/condition';
export const EMORI_ROOM_LIST_GET_REQUEST = 'emori/room/list/get/request';
export const EMORI_ROOM_LIST_GET_SUCCESS = 'emori/room/list/get/success';
export const EMORI_ROOM_LIST_GET_FAILED = 'emori/room/list/get/failed';

export const MEMBERSHIP_CARD_BENEFIT_CHECK_GET_REQUEST = 'membership/me/cards/benefit/check/request';
export const MEMBERSHIP_CARD_BENEFIT_CHECK_GET_SUCCESS = 'membership/me/cards/benefit/check/success';
export const MEMBERSHIP_CARD_BENEFIT_CHECK_GET_FAILED = 'membership/me/cards/benefit/check/failed';
export const MEMBERSHIP_CARD_BENEFIT_CHECK_SET = 'membership/me/cards/benefit/check/set';

export const EMORI_ROOM_PROFILE_LIST_GET_REQUEST = 'emori/room/profile/list/get/request';
export const EMORI_ROOM_PROFILE_LIST_GET_SUCCESS = 'emori/room/profile/list/get/success';
export const EMORI_ROOM_PROFILE_LIST_GET_FAILED = 'emori/room/profile/list/get/failed';

export const EMORI_GET_ICON_LIST_REQUEST = 'emori/icon/get/request';
export const EMORI_GET_ICON_LIST_SUCCESS = 'emori/icon/get/success';
export const EMORI_GET_ICON_LIST_FAILED = 'emori/icon/get/failed';

