// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-credit-cards-js": () => import("./../../../src/pages/addCreditCards.js" /* webpackChunkName: "component---src-pages-add-credit-cards-js" */),
  "component---src-pages-appointment-js": () => import("./../../../src/pages/appointment.js" /* webpackChunkName: "component---src-pages-appointment-js" */),
  "component---src-pages-assessment-index-js": () => import("./../../../src/pages/assessment/index.js" /* webpackChunkName: "component---src-pages-assessment-index-js" */),
  "component---src-pages-assessment-menu-js": () => import("./../../../src/pages/assessment/menu.js" /* webpackChunkName: "component---src-pages-assessment-menu-js" */),
  "component---src-pages-assessment-personal-info-js": () => import("./../../../src/pages/assessment/personalInfo.js" /* webpackChunkName: "component---src-pages-assessment-personal-info-js" */),
  "component---src-pages-assessment-success-js": () => import("./../../../src/pages/assessment/success.js" /* webpackChunkName: "component---src-pages-assessment-success-js" */),
  "component---src-pages-assessment-symptom-entry-js": () => import("./../../../src/pages/assessment/symptomEntry.js" /* webpackChunkName: "component---src-pages-assessment-symptom-entry-js" */),
  "component---src-pages-assessment-symptom-loop-js": () => import("./../../../src/pages/assessment/symptomLoop.js" /* webpackChunkName: "component---src-pages-assessment-symptom-loop-js" */),
  "component---src-pages-assessment-vital-sign-info-js": () => import("./../../../src/pages/assessment/vitalSignInfo.js" /* webpackChunkName: "component---src-pages-assessment-vital-sign-info-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blogs-js": () => import("./../../../src/pages/blogs.js" /* webpackChunkName: "component---src-pages-blogs-js" */),
  "component---src-pages-categories-js": () => import("./../../../src/pages/categories.js" /* webpackChunkName: "component---src-pages-categories-js" */),
  "component---src-pages-certificate-index-js": () => import("./../../../src/pages/certificate/index.js" /* webpackChunkName: "component---src-pages-certificate-index-js" */),
  "component---src-pages-coming-soon-index-js": () => import("./../../../src/pages/comingSoon/index.js" /* webpackChunkName: "component---src-pages-coming-soon-index-js" */),
  "component---src-pages-consult-doctor-app-channel-js": () => import("./../../../src/pages/consult/doctorApp/channel.js" /* webpackChunkName: "component---src-pages-consult-doctor-app-channel-js" */),
  "component---src-pages-consult-doctor-app-chat-js": () => import("./../../../src/pages/consult/doctorApp/chat.js" /* webpackChunkName: "component---src-pages-consult-doctor-app-chat-js" */),
  "component---src-pages-consult-doctor-app-close-case-js": () => import("./../../../src/pages/consult/doctorApp/closeCase.js" /* webpackChunkName: "component---src-pages-consult-doctor-app-close-case-js" */),
  "component---src-pages-consult-doctor-app-index-js": () => import("./../../../src/pages/consult/doctorApp/index.js" /* webpackChunkName: "component---src-pages-consult-doctor-app-index-js" */),
  "component---src-pages-consult-doctor-app-registration-js": () => import("./../../../src/pages/consult/doctorApp/registration.js" /* webpackChunkName: "component---src-pages-consult-doctor-app-registration-js" */),
  "component---src-pages-consult-doctor-app-summary-js": () => import("./../../../src/pages/consult/doctorApp/summary.js" /* webpackChunkName: "component---src-pages-consult-doctor-app-summary-js" */),
  "component---src-pages-consult-patient-app-appointment-js": () => import("./../../../src/pages/consult/patientApp/appointment.js" /* webpackChunkName: "component---src-pages-consult-patient-app-appointment-js" */),
  "component---src-pages-consult-patient-app-channel-js": () => import("./../../../src/pages/consult/patientApp/channel.js" /* webpackChunkName: "component---src-pages-consult-patient-app-channel-js" */),
  "component---src-pages-consult-patient-app-chat-js": () => import("./../../../src/pages/consult/patientApp/chat.js" /* webpackChunkName: "component---src-pages-consult-patient-app-chat-js" */),
  "component---src-pages-consult-patient-app-choose-category-js": () => import("./../../../src/pages/consult/patientApp/chooseCategory.js" /* webpackChunkName: "component---src-pages-consult-patient-app-choose-category-js" */),
  "component---src-pages-consult-patient-app-choose-consultant-js": () => import("./../../../src/pages/consult/patientApp/chooseConsultant.js" /* webpackChunkName: "component---src-pages-consult-patient-app-choose-consultant-js" */),
  "component---src-pages-consult-patient-app-confirmation-js": () => import("./../../../src/pages/consult/patientApp/confirmation.js" /* webpackChunkName: "component---src-pages-consult-patient-app-confirmation-js" */),
  "component---src-pages-consult-patient-app-index-js": () => import("./../../../src/pages/consult/patientApp/index.js" /* webpackChunkName: "component---src-pages-consult-patient-app-index-js" */),
  "component---src-pages-consult-patient-app-kyc-js": () => import("./../../../src/pages/consult/patientApp/kyc.js" /* webpackChunkName: "component---src-pages-consult-patient-app-kyc-js" */),
  "component---src-pages-consult-patient-app-personal-info-js": () => import("./../../../src/pages/consult/patientApp/personalInfo.js" /* webpackChunkName: "component---src-pages-consult-patient-app-personal-info-js" */),
  "component---src-pages-consult-patient-app-summary-js": () => import("./../../../src/pages/consult/patientApp/summary.js" /* webpackChunkName: "component---src-pages-consult-patient-app-summary-js" */),
  "component---src-pages-consult-patient-app-vital-sign-info-js": () => import("./../../../src/pages/consult/patientApp/vitalSignInfo.js" /* webpackChunkName: "component---src-pages-consult-patient-app-vital-sign-info-js" */),
  "component---src-pages-consultants-js": () => import("./../../../src/pages/consultants.js" /* webpackChunkName: "component---src-pages-consultants-js" */),
  "component---src-pages-count-js": () => import("./../../../src/pages/count.js" /* webpackChunkName: "component---src-pages-count-js" */),
  "component---src-pages-drug-store-checkout-js": () => import("./../../../src/pages/drugStore/checkout.js" /* webpackChunkName: "component---src-pages-drug-store-checkout-js" */),
  "component---src-pages-drug-store-index-js": () => import("./../../../src/pages/drugStore/index.js" /* webpackChunkName: "component---src-pages-drug-store-index-js" */),
  "component---src-pages-drug-store-order-js": () => import("./../../../src/pages/drugStore/order.js" /* webpackChunkName: "component---src-pages-drug-store-order-js" */),
  "component---src-pages-emori-choose-emori-js": () => import("./../../../src/pages/emori/chooseEmori.js" /* webpackChunkName: "component---src-pages-emori-choose-emori-js" */),
  "component---src-pages-emori-graph-emori-js": () => import("./../../../src/pages/emori/graphEmori.js" /* webpackChunkName: "component---src-pages-emori-graph-emori-js" */),
  "component---src-pages-emori-index-js": () => import("./../../../src/pages/emori/index.js" /* webpackChunkName: "component---src-pages-emori-index-js" */),
  "component---src-pages-emori-relation-add-profile-success-js": () => import("./../../../src/pages/emori/relation/addProfileSuccess.js" /* webpackChunkName: "component---src-pages-emori-relation-add-profile-success-js" */),
  "component---src-pages-emori-relation-choose-category-js": () => import("./../../../src/pages/emori/relation/chooseCategory.js" /* webpackChunkName: "component---src-pages-emori-relation-choose-category-js" */),
  "component---src-pages-emori-relation-choose-emori-js": () => import("./../../../src/pages/emori/relation/chooseEmori.js" /* webpackChunkName: "component---src-pages-emori-relation-choose-emori-js" */),
  "component---src-pages-emori-relation-create-room-js": () => import("./../../../src/pages/emori/relation/createRoom.js" /* webpackChunkName: "component---src-pages-emori-relation-create-room-js" */),
  "component---src-pages-emori-relation-index-js": () => import("./../../../src/pages/emori/relation/index.js" /* webpackChunkName: "component---src-pages-emori-relation-index-js" */),
  "component---src-pages-emori-relation-manage-member-js": () => import("./../../../src/pages/emori/relation/manageMember.js" /* webpackChunkName: "component---src-pages-emori-relation-manage-member-js" */),
  "component---src-pages-emori-relation-modify-profile-admin-js": () => import("./../../../src/pages/emori/relation/modifyProfileAdmin.js" /* webpackChunkName: "component---src-pages-emori-relation-modify-profile-admin-js" */),
  "component---src-pages-emori-relation-modify-profile-member-js": () => import("./../../../src/pages/emori/relation/modifyProfileMember.js" /* webpackChunkName: "component---src-pages-emori-relation-modify-profile-member-js" */),
  "component---src-pages-emori-relation-modify-room-js": () => import("./../../../src/pages/emori/relation/modifyRoom.js" /* webpackChunkName: "component---src-pages-emori-relation-modify-room-js" */),
  "component---src-pages-emori-relation-profile-admin-js": () => import("./../../../src/pages/emori/relation/profileAdmin.js" /* webpackChunkName: "component---src-pages-emori-relation-profile-admin-js" */),
  "component---src-pages-emori-relation-profile-member-js": () => import("./../../../src/pages/emori/relation/profileMember.js" /* webpackChunkName: "component---src-pages-emori-relation-profile-member-js" */),
  "component---src-pages-emori-relation-register-js": () => import("./../../../src/pages/emori/relation/register.js" /* webpackChunkName: "component---src-pages-emori-relation-register-js" */),
  "component---src-pages-emori-relation-room-js": () => import("./../../../src/pages/emori/relation/room.js" /* webpackChunkName: "component---src-pages-emori-relation-room-js" */),
  "component---src-pages-emori-summary-date-js": () => import("./../../../src/pages/emori/summaryDate.js" /* webpackChunkName: "component---src-pages-emori-summary-date-js" */),
  "component---src-pages-emori-summary-month-js": () => import("./../../../src/pages/emori/summaryMonth.js" /* webpackChunkName: "component---src-pages-emori-summary-month-js" */),
  "component---src-pages-forget-password-js": () => import("./../../../src/pages/forgetPassword.js" /* webpackChunkName: "component---src-pages-forget-password-js" */),
  "component---src-pages-history-index-js": () => import("./../../../src/pages/history/index.js" /* webpackChunkName: "component---src-pages-history-index-js" */),
  "component---src-pages-holistic-js": () => import("./../../../src/pages/holistic.js" /* webpackChunkName: "component---src-pages-holistic-js" */),
  "component---src-pages-home-js": () => import("./../../../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kyc-index-js": () => import("./../../../src/pages/kyc/index.js" /* webpackChunkName: "component---src-pages-kyc-index-js" */),
  "component---src-pages-kyc-kyc-success-js": () => import("./../../../src/pages/kyc/kycSuccess.js" /* webpackChunkName: "component---src-pages-kyc-kyc-success-js" */),
  "component---src-pages-lab-store-checkout-js": () => import("./../../../src/pages/labStore/checkout.js" /* webpackChunkName: "component---src-pages-lab-store-checkout-js" */),
  "component---src-pages-lab-store-index-js": () => import("./../../../src/pages/labStore/index.js" /* webpackChunkName: "component---src-pages-lab-store-index-js" */),
  "component---src-pages-lab-store-order-js": () => import("./../../../src/pages/labStore/order.js" /* webpackChunkName: "component---src-pages-lab-store-order-js" */),
  "component---src-pages-lab-store-personal-info-js": () => import("./../../../src/pages/labStore/personalInfo.js" /* webpackChunkName: "component---src-pages-lab-store-personal-info-js" */),
  "component---src-pages-lab-store-support-js": () => import("./../../../src/pages/labStore/support.js" /* webpackChunkName: "component---src-pages-lab-store-support-js" */),
  "component---src-pages-landing-download-1-js": () => import("./../../../src/pages/landing/download1.js" /* webpackChunkName: "component---src-pages-landing-download-1-js" */),
  "component---src-pages-line-login-callback-js": () => import("./../../../src/pages/lineLoginCallback.js" /* webpackChunkName: "component---src-pages-line-login-callback-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-mbanking-js": () => import("./../../../src/pages/mbanking.js" /* webpackChunkName: "component---src-pages-mbanking-js" */),
  "component---src-pages-membership-cannot-use-benefit-js": () => import("./../../../src/pages/membership/cannotUseBenefit.js" /* webpackChunkName: "component---src-pages-membership-cannot-use-benefit-js" */),
  "component---src-pages-membership-checkout-js": () => import("./../../../src/pages/membership/checkout.js" /* webpackChunkName: "component---src-pages-membership-checkout-js" */),
  "component---src-pages-membership-consult-appointment-js": () => import("./../../../src/pages/membership/consult/appointment.js" /* webpackChunkName: "component---src-pages-membership-consult-appointment-js" */),
  "component---src-pages-membership-consult-choose-category-js": () => import("./../../../src/pages/membership/consult/chooseCategory.js" /* webpackChunkName: "component---src-pages-membership-consult-choose-category-js" */),
  "component---src-pages-membership-consult-choose-consultant-js": () => import("./../../../src/pages/membership/consult/chooseConsultant.js" /* webpackChunkName: "component---src-pages-membership-consult-choose-consultant-js" */),
  "component---src-pages-membership-consult-confirmation-js": () => import("./../../../src/pages/membership/consult/confirmation.js" /* webpackChunkName: "component---src-pages-membership-consult-confirmation-js" */),
  "component---src-pages-membership-consult-personal-info-js": () => import("./../../../src/pages/membership/consult/personalInfo.js" /* webpackChunkName: "component---src-pages-membership-consult-personal-info-js" */),
  "component---src-pages-membership-consult-vital-sign-info-js": () => import("./../../../src/pages/membership/consult/vitalSignInfo.js" /* webpackChunkName: "component---src-pages-membership-consult-vital-sign-info-js" */),
  "component---src-pages-membership-index-js": () => import("./../../../src/pages/membership/index.js" /* webpackChunkName: "component---src-pages-membership-index-js" */),
  "component---src-pages-membership-order-js": () => import("./../../../src/pages/membership/order.js" /* webpackChunkName: "component---src-pages-membership-order-js" */),
  "component---src-pages-membership-pricing-js": () => import("./../../../src/pages/membership/pricing.js" /* webpackChunkName: "component---src-pages-membership-pricing-js" */),
  "component---src-pages-membership-redeem-js": () => import("./../../../src/pages/membership/redeem.js" /* webpackChunkName: "component---src-pages-membership-redeem-js" */),
  "component---src-pages-membership-redeem-success-js": () => import("./../../../src/pages/membership/redeemSuccess.js" /* webpackChunkName: "component---src-pages-membership-redeem-success-js" */),
  "component---src-pages-membership-result-gift-js": () => import("./../../../src/pages/membership/resultGift.js" /* webpackChunkName: "component---src-pages-membership-result-gift-js" */),
  "component---src-pages-menu-check-up-index-js": () => import("./../../../src/pages/menu/CheckUp/index.js" /* webpackChunkName: "component---src-pages-menu-check-up-index-js" */),
  "component---src-pages-menu-child-01-js": () => import("./../../../src/pages/menu/child/01.js" /* webpackChunkName: "component---src-pages-menu-child-01-js" */),
  "component---src-pages-menu-child-02-js": () => import("./../../../src/pages/menu/child/02.js" /* webpackChunkName: "component---src-pages-menu-child-02-js" */),
  "component---src-pages-menu-child-03-js": () => import("./../../../src/pages/menu/child/03.js" /* webpackChunkName: "component---src-pages-menu-child-03-js" */),
  "component---src-pages-menu-child-04-js": () => import("./../../../src/pages/menu/child/04.js" /* webpackChunkName: "component---src-pages-menu-child-04-js" */),
  "component---src-pages-menu-child-05-js": () => import("./../../../src/pages/menu/child/05.js" /* webpackChunkName: "component---src-pages-menu-child-05-js" */),
  "component---src-pages-menu-child-06-js": () => import("./../../../src/pages/menu/child/06.js" /* webpackChunkName: "component---src-pages-menu-child-06-js" */),
  "component---src-pages-menu-child-07-js": () => import("./../../../src/pages/menu/child/07.js" /* webpackChunkName: "component---src-pages-menu-child-07-js" */),
  "component---src-pages-menu-child-08-js": () => import("./../../../src/pages/menu/child/08.js" /* webpackChunkName: "component---src-pages-menu-child-08-js" */),
  "component---src-pages-menu-child-index-js": () => import("./../../../src/pages/menu/child/index.js" /* webpackChunkName: "component---src-pages-menu-child-index-js" */),
  "component---src-pages-menu-child-insocial-index-js": () => import("./../../../src/pages/menu/ChildInsocial/index.js" /* webpackChunkName: "component---src-pages-menu-child-insocial-index-js" */),
  "component---src-pages-menu-child-sensory-js": () => import("./../../../src/pages/menu/child/Sensory.js" /* webpackChunkName: "component---src-pages-menu-child-sensory-js" */),
  "component---src-pages-menu-demo-appointment-js": () => import("./../../../src/pages/menu/Demo/appointment.js" /* webpackChunkName: "component---src-pages-menu-demo-appointment-js" */),
  "component---src-pages-menu-finemotor-nwriting-index-js": () => import("./../../../src/pages/menu/finemotorNwriting/index.js" /* webpackChunkName: "component---src-pages-menu-finemotor-nwriting-index-js" */),
  "component---src-pages-menu-infant-index-js": () => import("./../../../src/pages/menu/infant/index.js" /* webpackChunkName: "component---src-pages-menu-infant-index-js" */),
  "component---src-pages-menu-mental-index-js": () => import("./../../../src/pages/menu/mental/index.js" /* webpackChunkName: "component---src-pages-menu-mental-index-js" */),
  "component---src-pages-menu-ncd-index-js": () => import("./../../../src/pages/menu/ncd/index.js" /* webpackChunkName: "component---src-pages-menu-ncd-index-js" */),
  "component---src-pages-menu-old-index-js": () => import("./../../../src/pages/menu/old/index.js" /* webpackChunkName: "component---src-pages-menu-old-index-js" */),
  "component---src-pages-menu-speech-language-index-js": () => import("./../../../src/pages/menu/speechLanguage/index.js" /* webpackChunkName: "component---src-pages-menu-speech-language-index-js" */),
  "component---src-pages-menu-tele-pharmacy-index-js": () => import("./../../../src/pages/menu/TelePharmacy/index.js" /* webpackChunkName: "component---src-pages-menu-tele-pharmacy-index-js" */),
  "component---src-pages-nhso-index-js": () => import("./../../../src/pages/nhso/index.js" /* webpackChunkName: "component---src-pages-nhso-index-js" */),
  "component---src-pages-notification-js": () => import("./../../../src/pages/notification.js" /* webpackChunkName: "component---src-pages-notification-js" */),
  "component---src-pages-otp-2-js": () => import("./../../../src/pages/otp2.js" /* webpackChunkName: "component---src-pages-otp-2-js" */),
  "component---src-pages-otp-js": () => import("./../../../src/pages/otp.js" /* webpackChunkName: "component---src-pages-otp-js" */),
  "component---src-pages-package-js": () => import("./../../../src/pages/package.js" /* webpackChunkName: "component---src-pages-package-js" */),
  "component---src-pages-partners-assessment-index-js": () => import("./../../../src/pages/partners/assessment/index.js" /* webpackChunkName: "component---src-pages-partners-assessment-index-js" */),
  "component---src-pages-partners-assessment-menu-js": () => import("./../../../src/pages/partners/assessment/menu.js" /* webpackChunkName: "component---src-pages-partners-assessment-menu-js" */),
  "component---src-pages-partners-consult-appointment-js": () => import("./../../../src/pages/partners/consult/appointment.js" /* webpackChunkName: "component---src-pages-partners-consult-appointment-js" */),
  "component---src-pages-partners-consult-choose-category-js": () => import("./../../../src/pages/partners/consult/chooseCategory.js" /* webpackChunkName: "component---src-pages-partners-consult-choose-category-js" */),
  "component---src-pages-partners-consult-choose-consultant-js": () => import("./../../../src/pages/partners/consult/chooseConsultant.js" /* webpackChunkName: "component---src-pages-partners-consult-choose-consultant-js" */),
  "component---src-pages-partners-consult-confirmation-js": () => import("./../../../src/pages/partners/consult/confirmation.js" /* webpackChunkName: "component---src-pages-partners-consult-confirmation-js" */),
  "component---src-pages-partners-consult-personal-info-js": () => import("./../../../src/pages/partners/consult/personalInfo.js" /* webpackChunkName: "component---src-pages-partners-consult-personal-info-js" */),
  "component---src-pages-partners-consult-vital-sign-info-js": () => import("./../../../src/pages/partners/consult/vitalSignInfo.js" /* webpackChunkName: "component---src-pages-partners-consult-vital-sign-info-js" */),
  "component---src-pages-partners-edit-profile-js": () => import("./../../../src/pages/partners/editProfile.js" /* webpackChunkName: "component---src-pages-partners-edit-profile-js" */),
  "component---src-pages-partners-emori-index-js": () => import("./../../../src/pages/partners/emori/index.js" /* webpackChunkName: "component---src-pages-partners-emori-index-js" */),
  "component---src-pages-partners-index-js": () => import("./../../../src/pages/partners/index.js" /* webpackChunkName: "component---src-pages-partners-index-js" */),
  "component---src-pages-partners-login-js": () => import("./../../../src/pages/partners/login.js" /* webpackChunkName: "component---src-pages-partners-login-js" */),
  "component---src-pages-partners-profile-js": () => import("./../../../src/pages/partners/profile.js" /* webpackChunkName: "component---src-pages-partners-profile-js" */),
  "component---src-pages-partners-register-js": () => import("./../../../src/pages/partners/register.js" /* webpackChunkName: "component---src-pages-partners-register-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/paymentSuccess.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-pick-location-js": () => import("./../../../src/pages/pickLocation.js" /* webpackChunkName: "component---src-pages-pick-location-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-promotion-index-js": () => import("./../../../src/pages/promotion/index.js" /* webpackChunkName: "component---src-pages-promotion-index-js" */),
  "component---src-pages-questionnaire-js": () => import("./../../../src/pages/questionnaire.js" /* webpackChunkName: "component---src-pages-questionnaire-js" */),
  "component---src-pages-questionnaire-success-js": () => import("./../../../src/pages/questionnaire_success.js" /* webpackChunkName: "component---src-pages-questionnaire-success-js" */),
  "component---src-pages-questionnaires-index-js": () => import("./../../../src/pages/questionnaires/index.js" /* webpackChunkName: "component---src-pages-questionnaires-index-js" */),
  "component---src-pages-questionnaires-menu-js": () => import("./../../../src/pages/questionnaires/menu.js" /* webpackChunkName: "component---src-pages-questionnaires-menu-js" */),
  "component---src-pages-request-contact-js": () => import("./../../../src/pages/request_contact.js" /* webpackChunkName: "component---src-pages-request-contact-js" */),
  "component---src-pages-result-appointment-success-js": () => import("./../../../src/pages/result/appointmentSuccess.js" /* webpackChunkName: "component---src-pages-result-appointment-success-js" */),
  "component---src-pages-result-cardpayment-js": () => import("./../../../src/pages/result/cardpayment.js" /* webpackChunkName: "component---src-pages-result-cardpayment-js" */),
  "component---src-pages-result-js": () => import("./../../../src/pages/result.js" /* webpackChunkName: "component---src-pages-result-js" */),
  "component---src-pages-result-mbanking-js": () => import("./../../../src/pages/result/mbanking.js" /* webpackChunkName: "component---src-pages-result-mbanking-js" */),
  "component---src-pages-result-payment-success-js": () => import("./../../../src/pages/result/paymentSuccess.js" /* webpackChunkName: "component---src-pages-result-payment-success-js" */),
  "component---src-pages-result-scan-promptpay-js": () => import("./../../../src/pages/result/scanPromptpay.js" /* webpackChunkName: "component---src-pages-result-scan-promptpay-js" */),
  "component---src-pages-scan-promptpay-js": () => import("./../../../src/pages/scanPromptpay.js" /* webpackChunkName: "component---src-pages-scan-promptpay-js" */),
  "component---src-pages-statistic-js": () => import("./../../../src/pages/statistic.js" /* webpackChunkName: "component---src-pages-statistic-js" */)
}

