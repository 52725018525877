import * as Constants from '../actions/constants';

const initialState = {
  month: null,
  year: null,
  day: null,
  emori: {},
  roomList: {},
  profile: {},
  corporate: null,
  room: null,
  search: [],
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.SET_MONTH:
      return {
        ...state,
        month: action.payload.month,
        year: action.payload.year,
      };
    case Constants.SET_DATE:
      return {
        ...state,
        day: action.payload,
      };
    case Constants.SET_RELATION_CORPORATE:
      return {
        ...state,
        corporate: action.payload,
      };
    case Constants.SET_RELATION_ROOM:
      return {
        ...state,
        room: action.payload,
      };
    case Constants.SET_RELATION_SEARCH:
      return {
        ...state,
        search: [...state.search, action.payload],
      };
    case Constants.EMORI_GET_SUCCESS:
      return {
        ...state,
        emori: {
          ...state.emori,
          ...action.payload.results,
        },
      };
    case Constants.EMORI_ROOM_LIST_GET_SUCCESS:
      const rooms = action.payload.results.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        };
      }, state.roomList);
      return {
        ...state,
        roomList: {
          ...state.roomList,
          ...rooms,
        },
      }
    case Constants.EMORI_ROOM_PROFILE_LIST_GET_SUCCESS:
      const profile = action.payload.results.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        };
      }, state.profile);
      return {
        ...state,
        profile: {
          ...state.profile,
          ...profile,
        },
      }
    default:
      return state;
  }
}

export const selectedIsMemberCorporate = value => state => {
  return Object.entries(state.emori.roomList)
  .filter(([_, item]) => item['is_member'] === value)
  .map(([_, item]) => item.corporate.name);
};

export const createEmoriRoomsSelector = state =>
  Object.keys(state.emori.roomList).map(id => state.emori.roomList[id]);

export const createEmoriRoomByIdSelector = (state, id) =>
  state.emori.roomList[id];

export const createEmoriProfileSelector = state =>
  Object.keys(state.emori.profile).map(id => state.emori.profile[id]);

export const createEmoriProfileByIdSelector = (state, id) =>
  state.emori.profile[id];
