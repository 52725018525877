import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

import PromptSet from './assets/fonts/Prompt-Medium.ttf';
import SukhumvitSet from './assets/fonts/SukhumvitSet-Medium.ttf';
import GothamMedium from './assets/fonts/GothamMedium.ttf';
import GothamLight from './assets/fonts/GothamLight.ttf';
import QuickSandSet from './assets/fonts/Quicksand.ttf';

const quickSand = {
  fontFamily: 'QuickSand',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${QuickSandSet}) format('truetype')`,
};

const quickSandBold = {
  fontFamily: 'QuickSand',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${QuickSandSet}) format('truetype')`,
};

const prompt = {
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${PromptSet}) format('truetype')`,
};

const sukhumvit = {
  fontFamily: 'Sukhumvit',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${SukhumvitSet}) format('truetype')`,
  unicodeRange: 'U+0E00-U+0E7F',
};

const gotham = {
  fontFamily: 'Gotham',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `url(${GothamLight}) format('truetype')`,
  unicodeRange: 'U+41-5A, U+61-7A, U+30-39, U+A0',
};

const gothamBold = {
  fontFamily: 'Gotham',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `url(${GothamMedium}) format('truetype')`,
  unicodeRange: 'U+41-5A, U+61-7A, U+30-39, U+A0',
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    text: {
      primary: "#4677a8",
      secondary: "#00000"
    },
    primary: {
      main: '#4677a8',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Sukhumvit'
    ].join(','),
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [sukhumvit, gotham, gothamBold, prompt, quickSand, quickSandBold],
      },
    },
  },
});

export default theme;
