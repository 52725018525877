import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const byId = (state = {}, action) => {
  switch (action.type) {
    case Constants.CHATROOM_GET_SUCCESS: {
      const chatroom = action.payload;

      chatroom.receipt = ((receipt) => {
        if (!chatroom.is_close || !receipt) return null;

        return {
          id: receipt.id,
          receiptId: receipt.receipt_id,
          fileUrl: receipt.file
        }
      })(chatroom.appointment.receipt);

      return {
        ...state,
        [action.payload.id]: chatroom,
      };
    }
    case Constants.CHATROOMS_GET_SUCCESS: {
      return action.payload.results.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        };
      }, state);
    }
    default:
      return state;
  }
};

const descriptorById = (state = {}, action) => {
  switch (action.type) {
    case Constants.USERS_DESCRIPTOR_GET_SUCCESS: {
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.identity]: item,
        };
      }, state);
    }
    default:
      return state;
  }
};

const patientRecord = (state = {}, action) => {
  switch (action.type) {
    case Constants.CHATROOM_PATIENT_RECORDS_GET_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const evaluationRecord = (state = [], action) => {
  switch (action.type) {
    case Constants.CHATROOM_EVALUATION_RECORDS_GET_SUCCESS:
      return action.payload.results;
    default:
      return state;
  }
};
const labRecord = (state = [], action) => {
  switch (action.type) {
    case Constants.CHATROOM_LAB_RECORDS_GET_SUCCESS:
      return action.payload.results;
    default:
      return state;
  }
};
const questionaireRecord = (state = [], action) => {
  switch (action.type) {
    case Constants.CHATROOM_QUESTIONAIRE_RECORDS_GET_SUCCESS:
      return action.payload.results;
    default:
      return state;
  }
};
const appointmentRecord = (state = [], action) => {
  switch (action.type) {
    case Constants.CHATROOM_APPOINTMENT_RECORDS_GET_SUCCESS:
      return action.payload.results;
    default:
      return state;
  }
};

const messages = (state = [], action) => {
  switch (action.type) {
    case Constants.LOCATION_CHANGE: {
      return [];
    }
    case Constants.MESSAGE_POST_SUCCESS:
    case Constants.MESSAGES_GET_SUCCESS: {
      return action.payload.items;
    }
    default:
      return state;
  }
};

export default combineReducers({
  evaluationRecord,
  labRecord,
  questionaireRecord,
  appointmentRecord,
  patientRecord,
  descriptorById,
  messages,
  byId,
});

export const createUserDescriptorSelector = (state, id) =>
  state.chatrooms.descriptorById[id];
export const createChatroomSelector = state => state.chatrooms;
export const createAllChatroomsSelector = state =>
  Object.keys(state.chatrooms.byId).map(id => state.chatrooms.byId[id]);
export const createChatroomSelectorById = (state, id) =>
  state.chatrooms.byId[id];
