import { navigate } from 'gatsby';
import * as Constant from './constants';
import * as Api from '../apis';
// import * as Twilio from '../apis/twilio';
import * as liff from '../apis/liff';

export const become = () => {
  return (dispatch, getState) => {
    return Api.become()
      .then(payload => {
        const { session } = getState();
        if (session.changed) {
          dispatch({ type: Constant.CHANGED_PROFILE, payload: false });
        }
        dispatch({ type: Constant.BECOME_USER, payload });
        window.setUserId(payload.unique_id);
        return payload;
      })
      .catch(message => {
        const payload = window.location.pathname;
        if ((payload !== '/login/') && (payload !== '/lineLoginCallback/') && (payload !== '/login') && (payload !== '/lineLoginCallback')) {
          localStorage.setItem('returnUri', `${payload}${window.location.search}`);
          navigate('/login');
        }
        dispatch({ type: Constant.NO_AUTHENTICATION });
      });
  };
};

export const logInFacebook = (accessToken, otpData) => {
  return async dispatch => {
    try {
      let payload = await Api.logInFacebook(accessToken, otpData);
      dispatch({ type: Constant.BECOME_USER, payload });
      // payload = await Twilio.init();
      // dispatch({ type: Constant.BECOME_TWILIO, payload });
      const returnUri = localStorage.getItem('returnUri');
      navigate(returnUri);
    } catch (message) {
      dispatch({ type: Constant.NO_AUTHENTICATION });
      // if (message.detail === '404') {
      //   navigate('/otp');
      // } else {
      //   alert('Something went wrong!')
      // }
    }
  };
};

export const becomeLineLogin = accessToken => {
  return async dispatch => {
    try {
      let payload = await Api.logInLine(accessToken, {});
      dispatch({ type: Constant.BECOME_USER, payload });
      // payload = await Twilio.init();
      // dispatch({ type: Constant.BECOME_TWILIO, payload });
      const returnUri = localStorage.getItem('returnUri');
      navigate(returnUri);
    } catch (message) {
      dispatch({ type: Constant.NO_AUTHENTICATION });
      // if (message.detail === '404') {
      //   navigate('/otp');
      // } else {
      //   alert('Something went wrong!')
      // }
    }
  };
};

export const becomeAppToken = token => {
  return async dispatch => {
    try {
      let payload = await Api.logInToken(token);
      payload.token = token;
      dispatch({ type: Constant.BECOME_USER, payload });
      window.setUserId(payload.unique_id);
      // payload = await Twilio.init();
      // dispatch({ type: Constant.BECOME_TWILIO, payload });
    } catch (message) {
      dispatch({ type: Constant.NO_AUTHENTICATION });
      // navigate('/otp', {
      //   state: { redirect: window.location.pathname },
      //   replace: true,
      // })
    }
  };
};

/**
 * =============
 * TODO: Login with FB Messenger extension token
 * =============
 */
export const becomeFbMsg = () => {
  // return dispatch => {
  //   return liff.initLiff()
  //     .then(payload => {
  //       dispatch({ type: Constant.BECOME_LINE, payload });
  //       return Api.logInLine(payload.accessToken);
  //     }).then(payload => {
  //       dispatch(getAddresses());
  //       dispatch(getCreditCards());
  //       dispatch({ type: Constant.BECOME_USER, payload });
  //       return payload;
  //     }).catch(message => {
  //       navigate('/otp', {
  //         state: { redirect: window.location.pathname },
  //         replace: true,
  //       })
  //     });
  // };
};

export const becomeLiff = liffId => {
  return async dispatch => {
    try {
      let payload = await liff.initLiff(liffId);
      dispatch({ type: Constant.BECOME_LINE, payload });
      payload = await Api.logInLine(payload.accessToken, {});
      dispatch({ type: Constant.BECOME_USER, payload });
      window.setUserId(payload.unique_id);
      // payload = await Twilio.init();
      // dispatch({ type: Constant.BECOME_TWILIO, payload });
    } catch (message) {
      dispatch({ type: Constant.NO_AUTHENTICATION });
      // const { pathname, search } = window.location;
      // localStorage.setItem('returnUri', `${pathname}${search}`);
      // navigate('/otp', {
      //   state: { redirect: window.location.pathname },
      //   replace: true,
      // });
    }
  };
};

export const loginLine = (accessToken, otpData) => {
  return dispatch => {
    dispatch({ type: Constant.LOGIN_LINE_REQUEST });
    return Api.logInLine(accessToken, otpData)
      .then(payload => {
        dispatch({ type: Constant.LOGIN_LINE_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.LOGIN_LINE_FAILED, message });
      });
  };
};

export const updateMe = (firstName, lastName) => {
  return dispatch => {
    dispatch({ type: Constant.UPDATE_ME_REQUEST });
    return Api.updateMe(firstName, lastName)
      .then(payload => {
        dispatch({ type: Constant.UPDATE_ME_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.UPDATE_ME_FAILED, message });
      });
  };
};

export const postVerifyOTP = (value, phone, token) => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.OTP_VERIFY_POST_REQUEST });
    return Api.postVerifyOTP(value, phone, token)
      .then(payload => {
        dispatch({ type: Constant.OTP_VERIFY_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({
          type: Constant.OTP_VERIFY_POST_FAILED,
          message,
          alert: true,
        });
      });
  };
};

export const verifyOTP = otpData => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.OTP_VERIFY_POST_REQUEST });

    return Promise.resolve()
      .then(() => {
        const { session } = getState();

        if (!session.user && !session.line && !session.facebook) {
          return Promise.reject('Session is required');
        }

        if (session.line) {
          return dispatch(loginLine(session.line.accessToken, otpData));
        } else if (session.facebook) {
          return dispatch(logInFacebook(session.facebook.accessToken, otpData));
        }

        return Promise.resolve();
      })
      .then(payload => {
        dispatch({ type: Constant.OTP_VERIFY_POST_SUCCESS, payload });
      })
      .catch(message => {
        dispatch({ type: Constant.OTP_VERIFY_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const postRequestOTP = phone => {
  return dispatch => {
    dispatch({ type: Constant.OTP_POST_REQUEST });
    return Api.postRequestOTP(phone)
      .then(payload => {
        dispatch({ type: Constant.OTP_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.OTP_POST_FAILED, message, alert: true });
        return Promise.reject(message);
      });
  };
};

export const becomeFacebook = payload => ({
  type: Constant.BECOME_FACEBOOK,
  payload,
});

export const becomeSource = payload => ({
  type: Constant.BECOME_SOURCE,
  payload,
});

export const becomeAnonymous = payload => ({
  type: Constant.NO_AUTHENTICATION,
  payload,
});

export const changedProfile = status => ({
  type: Constant.CHANGED_PROFILE,
  payload: status,
});
