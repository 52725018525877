import * as Constants from '../actions/constants';
import { deliveryTomorrow } from '../helpers/date';
import { PaymentType } from '../helpers/constants';

const initialState = {
  // common
  payment: null,
  paymentToken: null,

  // membership
  plan: null,
  buyFor: 'self',

  // appointment
  consentPatientInfo: true,
  consultantId: null,
  consultCategoryId: null,
  slotTimeId: null,
  slotTimeText: '',
  remark: '',
  promotionId: null,
  consultNow: false,

  // lab/drug store
  address: null,
  name: '',
  phone: '',

  deliveryDate: deliveryTomorrow(),
  deliveryPrice: 0,
  deliveryMethod: 'delivery', // delivery, post_shipment
  distance: 0,
  medicallabBranch: null,
  collaboration: null, // Object
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.SET_ORDER_INFO:
      return {
        ...state,
        ...action.payload,
      };
    case Constants.REMOVE_PROMOTION: {
      if (state.promotionId === action.payload) {
        return {
          ...state,
          promotionId: null,
        };
      }
      return state;
    }
    case Constants.SET_PROMOTION:
      return {
        ...state,
        promotionId: action.payload,
      };
    case Constants.SET_PROMPTPAY:
      return {
        ...state,
        payment: PaymentType.PROMPTPAY,
      };
    case Constants.SET_CASH:
      return {
        ...state,
        payment: PaymentType.CASH,
      };
    case Constants.SET_PAYMENT_TYPE:
      return {
        ...state,
        payment: action.payload,
      };
    case Constants.CREDIT_CARDS_GET_SUCCESS:
      return {
        ...state,
        payment: action.payload.find(res => !!res.is_default),
      };
    case Constants.CREDIT_CARD_PATCH_SUCCESS:
      return {
        ...state,
        payment: action.payload,
      };
    case Constants.UPDATE_ME_SUCCESS:
    case Constants.LOGIN_LINE_SUCCESS:
    case Constants.BECOME_USER:
      if (!action.payload) return state;
      return {
        ...state,
        name:
          action.payload.first_name &&
          `${action.payload.first_name} ${action.payload.last_name}`,
        phone: action.payload.phone,
        deliveryMethod: action.payload.is_member ? 'post_shipment' : 'delivery',
      };
    case Constants.ADDRESSES_GET_SUCCESS: {
      const address = action.payload[0];
      const result = { ...state };
      if (address) {
        result.address = address;
        if (address.phone) {
          result.phone = address.phone;
        }
      }
      return result;
    }
    case Constants.ADDRESS_POST_SUCCESS: {
      const address = action.payload;
      return {
        ...state,
        address: address,
      };
    }
    case Constants.COLLABORATION_GET_SUCCESS: {
      const res = {
        ...state,
        collaboration: action.payload,
      };
      return res;
    }
    // case Constants.APPOINTMENT_POST_SUCCESS:
    // case Constants.LAB_ORDER_POST_SUCCESS:
    // case Constants.ORDER_POST_SUCCESS:
    // return initialState;
    default:
      return state;
  }
}

export const createTenantSelector = state => state.tenantId;
