let liff;

export const initLiff = liffId => {
  if (process.env.NODE_ENV === 'development') {
    liff = {
      init: () => Promise.resolve(),
      sendMessages: () => Promise.resolve(),
      isInClient: () => (window.liff ? window.liff.isInClient() : false),
      openWindow: () => Promise.resolve(),
      closeWindow: () => Promise.resolve(),
      getProfile: () =>
        Promise.resolve({
          userId: 'U2818fd89a6f2db059b31511b4e63ec26',
          displayName: 'ً ً Krit',
          pictureUrl:
            'https://profile.line-scdn.net/0hKMrCzhebFGJPOj8Vv1trNXN_Gg84FBIqN1hdAm85SVRrC1Y3I18LV2M4HVdrAwQwewxZUT9tH1Ni',
        }),
      getAccessToken: () =>
        'eyJhbGciOiJIUzI1NiJ9.w7JrsCJz8HtbXfYoIhcYN8tnQHzXY8AcvtwD88q3_Qgilff2HqTlTJjf01QAY19p0wxVdiyqyH0f_ebP8w0BrKNQj_g-114MXZKSyjHbE5uEIbLmun3cEdvb6bV7VHyx.iv_YKOjPiroeW_77HlLh6WPzOniJ9ZHFt_thK2gDdZw',
    };
  } else {
    if (typeof window !== 'undefined') {
      liff = window.liff;
    }
  }

  return new Promise(resolve => {
    liff
      .init({
        liffId: liffId,
      })
      .then(() => liff.getProfile())
      .then(profile => {
        resolve({
          profile,
          accessToken: liff.getAccessToken(),
        });
      });
  });
};

export const sendMessages = (message = []) => liff.sendMessages(message);

export const openWindow = url => {
  liff.openWindow({
    url,
    external: true,
  });
};

export const getAccessToken = () => liff.getAccessToken();

export const getProfile = () => liff.getProfile();

export const closeWindow = () => liff.closeWindow();

export const getContext = () => liff.getContext();

export const isInClient = () => liff && liff.isInClient();
