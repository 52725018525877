import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const byId = (state = {}, action) => {
  switch (action.type) {
    case Constants.PROMOTION_GET_SUCCESS: {
      return action.payload.results.reduce((prev, item) => {
        return {
          ...prev,
          [item.promotion.id]: item,
        };
      }, state);
    }
    default:
      return state;
  }
};
export default combineReducers({
  byId,
});

export const createPromotionByIdSelector = (state, id) =>
  state.promotion.byId[id];
export const createPromotionsSelector = state =>
  Object.keys(state.promotion.byId).map(id => state.promotion.byId[id]);
