import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { becomeLiff, becomeAppToken, becomeFbMsg, become, becomeSource, becomeAnonymous } from './src/actions/session';
import { getUrlParams } from './src/helpers/location';
import { locationChange } from './src/actions/location';
import MomentUtils from '@date-io/moment';
import GlobalLoader from './src/components/GlobalLoader';
import theme from './src/theme';
import createStore from './src/stores/createStore';
import CssBaseline from '@material-ui/core/CssBaseline';
import './src/apis/firebase';
import 'react-credit-cards/es/styles-compiled.css';
import './src/style.css';
import 'react-image-lightbox/style.css';
import { PUBLIC } from './src/helpers/constants';

const store = createStore();

export const wrapRootElement = ({ element }) => {
  return (
    <Provider store={store}>
      {element}
    </Provider>
  )
}

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        {element}
        <CssBaseline />
        <GlobalLoader />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export const onClientEntry = () => {
  const query = getUrlParams(window.location.search)

  if (!!query.debug) {
    const VConsole = require('vconsole');
    new VConsole();
  }
  if (process.env.GATSBY_APP_TOKEN_TEST) {
    query.appToken = query.appToken || process.env.GATSBY_APP_TOKEN_TEST;
  }

  // Valid query - useLiff (deprecated), liffId, useFb, appToken, source
  // console.log(query);
  // console.log({
  //   'path': window.location.pathname,
  //   '!!query.liffId': !!query.liffId,
  //   '!!query.useFb': !!query.useFb,
  //   '!!query.appToken': !!query.appToken,
  //   '!(PUBLIC.includes(window.location.pathname))': !(PUBLIC.includes(window.location.pathname)),
  // })

  if (!!query.liffId) {
    // Line LIFF
    store.dispatch(becomeLiff(query.liffId));
    query.source = 'liff';
  } else if (!!query.useFb) {
    // FB Messenger Platform
    ((d, s, id) => {
      let js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.net/en_US/messenger.Extensions.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'Messenger');
    window.extAsyncInit = () => {
      store.dispatch(becomeFbMsg());
    };
    query.source = 'messenger';
  } else if (!!query.appToken) {
    store.dispatch(becomeAppToken(query.appToken));
  } else if (!(PUBLIC.includes(window.location.pathname))) {
    store.dispatch(become());
  } else {
    store.dispatch(becomeAnonymous());
  }

  if (!!query.source) {
    store.dispatch(becomeSource(query.source));
  }
};

export const onRouteUpdate = ({ location }) => {
  store.dispatch(locationChange(location));
};
