import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const planById = (state = {}, action) => {
  switch (action.type) {
    case Constants.MEMBERSHIP_PLAN_GET_SUCCESS: {
      return action.payload.results.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        };
      }, state);
    }
    default:
      return state;
  }
};

const cardById = (state = {}, action) => {
  switch (action.type) {
    case Constants.MEMBERSHIP_CARDS_GET_SUCCESS: {
      return action.payload.results.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        };
      }, state);
    }
    default:
      return state;
  }
};

const force = (state = false, action) => {
  switch (action.type) {
    case Constants.SET_FORCE:
      return action.payload
    default:
      return state;
  }
}

const cardId = (state = {}, action) => {
  switch (action.type) {
    case Constants.SET_CURRENT_MEMBERSHIP_CARD: {
      return {
        [action.payload.id]: action.payload,
      };
    }
    default:
      return state;
  }
}

export const canUseBenefit = (state = null, action) => {
  switch (action.type) {
    case Constants.MEMBERSHIP_CARD_BENEFIT_CHECK_GET_SUCCESS:
      const firstResult = Object.values(action.payload.result)[0];
      const { message } = firstResult;
      return message === 'พบสิทธิ์';
      
    case Constants.MEMBERSHIP_CARD_BENEFIT_CHECK_GET_FAILED:
      return false;
    
    case Constants.MEMBERSHIP_CARD_BENEFIT_CHECK_SET:
      return action.payload

    default:
      return state;
  }
};

export default combineReducers({
  planById,
  cardById,
  force,
  cardId,
  canUseBenefit,
});

export const createAllMembershipPlanSelector = state =>
  Object.keys(state.memberships.planById).map(
    id => state.memberships.planById[id],
  );
export const createMembershipCardSelector = state => {
  const list = Object.keys(state.memberships.cardById).map(
    id => state.memberships.cardById[id],
  );
  return list[0];
};

export const createMembershipCardId = (state, id) => state.memberships.cardById[id];

export const createMembershipCards = state => {
  const list = Object.keys(state.memberships.cardById).map(
    id => state.memberships.cardById[id],
  );
  return list;
};