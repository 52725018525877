import * as Constants from '../actions/constants';

const initialState = {
  questionnaireId: null,
  userQuestionnaireId: null,
  categories: [],
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.SET_QUESTIONNAIRE_ID:
      return {
        ...state,
        questionnaireId: action.questionnaireId,
      };
    case Constants.SET_USER_QUESTIONNAIRE_ID:
      return {
        ...state,
        userQuestionnaireId: action.userQuestionnaireId,
      };
    case Constants.GET_CATEGORIES_REQUEST:
    case Constants.GET_CATEGORIES_FAILED:
      return {
        ...state,
        categories: [],
      };
    case Constants.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload,
      };
    default:
      return state;
  }
}
