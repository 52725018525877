import axios from 'axios';
import qs from 'qs';

class RequestHelper {
  authKey = 'token';
  accessToken;

  getToken() {
    if (localStorage) {
      return localStorage.getItem('token');
    }
    return '';
  }

  _setToken(token) {
    this.accessToken = token;
    if (localStorage) {
      if (token) {
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
      }
    }
  }

  request(method = 'get', url, data, header, version = 'v1') {
    const headers =
      header ||
      {
        // 'Content-Type': 'application/json',
      };
    const token = this.accessToken || this.getToken();
    if (token) headers['Authorization'] = `${this.authKey} ${token}`;

    if (url[0] === '/') {
      url = url.slice(1);
    }

    return axios({
      url: `${process.env.GATSBY_API_URL}${version}/${url}`,
      method,
      data,
      headers,
    })
      .then(response => {
        return response.data.result || response.data.results || response.data;
      })
      .catch(error => {
        return Promise.reject(
          error.response.data || error.response.data.message,
        );
      });
  }

  patch(url, payload) {
    return this.request('patch', url, payload);
  }

  post(url, payload) {
    return this.request('post', url, payload);
  }

  post_v2(url, payload) {
    return this.request('post', url, payload, '', 'v2');
  }

  postFormData(url, payload) {
    return this.request('post', url, payload, {
      'Content-Type': 'multipart/form-data',
    });
  }

  get(url) {
    return this.request('get', url);
  }

  get_v2(url) {
    return this.request('get', url, '', '', 'v2');
  }

  delete(url) {
    return this.request('delete', url);
  }

  bulkDelete(url, payload) {
    return this.request('delete', url, payload);
  }

  logIn(username, password) {
    return this._logIn('', { username, password });
  }

  logInFb(username, password) {
    return this._logIn('fb', { username, password });
  }

  logInLine(accessToken, otpData) {
    const payload = {
      access_token: accessToken,
      ...otpData,
    };
    return this._logIn('line', qs.stringify(payload));
  }

  logInToken(token) {
    this.accessToken = token;
    return this.me();
  }

  logInFacebook(accessToken, otpData) {
    const payload = {
      access_token: accessToken,
      ...otpData,
    };
    return this._logIn('facebook', qs.stringify(payload));
  }

  logInTwilio() {
    return this.post(`auth/twilio_authentication/`);
  }

  _logIn(method = '', payload) {
    return this.post(`auth/${!!method ? `${method}/` : method}login/`, payload)
      .then(response => {
        this.accessToken = response.key;
        localStorage.setItem('token', this.accessToken);
        return this.me();
      })
      .catch(error => {
        localStorage.removeItem('token');
        return Promise.reject(error.message);
      });
  }

  logOut() {
    return this.post('auth/logout/').then(response => {
      this.accessToken = undefined;
      // if (typeof window !== 'undefined' && window.localStorage) {
      //   window.localStorage.removeItem('accessToken');
      // }
      return response;
    });
  }

  me() {
    return this.get('auth/me/');
  }

  updateMe(first_name, last_name, email) {
    return this.patch('auth/me/', { first_name, last_name, email });
  }
}

export default new RequestHelper();
