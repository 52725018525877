export function getUrlParams(url) {
  const params = {};
  (url + '?')
    .split('?')[1]
    .split('&')
    .forEach(function(pair) {
      pair = (pair + '=').split('=').map(decodeURIComponent);
      if (pair[0].length) {
        params[pair[0]] = pair[1];
      }
    });
  if (url.includes('returnUri')) {
    params.returnUri = url.split('returnUri=')[1];
  }
  return params;
}

export function getPartnerId(url) {
  const page = getUrlParams(url);
  const partnerId = page.partnerId;
  return partnerId;
}

export function getAddOnBenefit(url) {
  const page = getUrlParams(url);
  const addOnBenefit = page.add_on_benefit;
  return addOnBenefit;
}

export function getConsultantListAvailableCondition(url) {
  const page = getUrlParams(url);
  const isAvailableOnly = page.is_available_only;
  return isAvailableOnly;
}

export function getTab(url) {
  const params = {};
  const queryParams = new URLSearchParams(url.location.search);
  const tab = queryParams.get('tab') || 0;
  const partnerID = queryParams.get('partnerId') || 0;

  params.tab = tab;
  params.partnerId = partnerID;
  return params;
}