import { combineReducers } from 'redux';
import * as Constants from '../actions/constants';

const consultantById = (state = {}, action) => {
  switch (action.type) {
    case Constants.APPOINTMENT_CONSULTANTS_GET_SUCCESS: {
      return action.payload.reduce((prev, item) => {
        const consultant = item;
        consultant.medCertAvailable = item.medical_certificate_available;

        return {
          ...prev,
          [item.id]: consultant,
        };
      }, state);
    }
    default:
      return state;
  }
};

const consultantList = (state = [], action) => {
  switch (action.type) {
    case Constants.APPOINTMENT_CONSULTANTS_GET_SUCCESS: {
      return action.payload.map(item => item.id);
    }
    default:
      return state;
  }
};

const categoryById = (state = {}, action) => {
  switch (action.type) {
    case Constants.APPOINTMENT_CATEGORIES_GET_SUCCESS: {
      return action.payload.reduce((prev, item) => {
        return {
          ...prev,
          [item.id]: item,
        };
      }, state);
    }
    default:
      return state;
  }
};

export default combineReducers({
  consultantById,
  categoryById,
  consultantList,
});

export const createConsultantListSelector = state =>
  state.appointment.consultantList.map(
    id => state.appointment.consultantById[id],
  );
export const createAllConsultantsSelector = state =>
  Object.values(state.appointment.consultantById);
export const createAllCategoriesSelector = state =>
  Object.values(state.appointment.categoryById).sort(
    (a, b) => a.order - b.order,
  );
