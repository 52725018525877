import * as Constants from '../actions/constants';

const initialState = {
  requireField: null,
  verify: false,
  corporate: null,
  redirect: null,
  prevPage: null,
  cardId: null, 
  cardName: null,
  partnerId: null,
  addOnBenefit: undefined,
  consultantListAvailableOnly: undefined
};

export const createPartnerIdSelector = state => state.partners.partnerId;

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case Constants.APPOINTMENT_CATEGORIES_GET_FAILED:
      return {
        ...state,
        requireField: action.payload.message.requirefields,
        corporate: action.payload.message.corporate,
        redirect: action.payload.message.redirect_flow,
        prevPage: `${window.location.pathname}${window.location.search}`,
        registerFields: action.payload.message.registerfields.map(item => ({
          name: item.name,
          label: item.label,
          type: item.type,
          isRequired: item.is_required,
          placeholder: item.placeholder,
          validateRegex: item.validate_regex,
          choices: item.choices?.map(choice => {
            return { key: choice.key, value: choice.value }
          }) || null,
          
          validation: (value) => {
            if (item.type === 'multiple_choice' && !value.length) {
              value = null;
            }

            if (item.is_required && !value) {
              return `${item.label} is required`;
            }

            const match = item.validate_regex && !new RegExp(item.validate_regex).test(value);
            if (value && match) {
              return 'ค่าที่ให้มาไม่ตรงตามรูปแบบที่กำหนดไว้';
            }

            return true;
          }
        }))
      };
    case Constants.PARTNER_VERIFY_POST_SUCCESS:
      return {
        ...state,
        cardId: action.payload.card,
        verify: true,
      }
    case Constants.PARTNERS_CARDS_GET_SUCCESS:
      return {
        ...state,
        cardId: action.payload.count > 0 ? action.payload.results[0].id : null,
        cardName: action.payload.results[0].type.name,
        verify: true,
      }
    case Constants.SET_CORPORATE_ID:
      return {
        ...state,
        partnerId: action.payload,
      }
    case Constants.SET_ADD_ON_BENEFIT:
      return {
        ...state,
        addOnBenefit: action.payload,
      }
    case Constants.SET_CONSULTANT_LIST_AVAILABLE_CONDITION:
      return {
        ...state,
        consultantListAvailableOnly: action.payload,
      }
    default:
      return state;
  }
}
